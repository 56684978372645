import { getDomainByEnv, sendRequest } from 'utils/utils'

export const webFlowAddress = {
	getBranchesList: ({
		formatted,
		lat,
		lng,
		chainId,
		lang,
		orderType,
		showLoader = true,
	}: {
		formatted: any
		lat: any
		lng: any
		chainId: any
		lang: any
		orderType: any
		showLoader: any
	}) => {
		const isAddressEmpty = !formatted && !lat && !lng
		let requestUrl = `${getDomainByEnv()}webFlowAddress`

		if (isAddressEmpty) {
			requestUrl += `?chainId=${chainId}&lang=${lang}&type=getBranchesList&cust=openRest&noFilter=true`
		}

		const requestPayload = {
			chainId,
			type: 'getBranchesList',
			addr: isAddressEmpty ? {} : { formatted, lat, lng },
			cust: 'openRest',
			lang,
			orderType,
			noFilter: true,
		}

		return sendRequest(
			showLoader,
			requestUrl,
			isAddressEmpty ? 'get' : 'post',
			requestPayload,
			{
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
			},
			undefined,
			undefined,
			null,
			false
		)
	},
}
