// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components/macro'

const CommentsRoot = styled.div`
	padding: 10px 22px;
`

const StyledTypographyTitle = styled(Typography)`
	color: black;
	font-family: Oswald, 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 10px;

	@media (min-width: 1280px) {
		font-size: 23px;
		line-height: 31px;
	}
`

const StyledTextArea = styled.textarea`
	width: 100%;
	background-color: #f3f3f3;
	border: 0;
	resize: none;
	padding: 5px;
	font-size: 12px;

	@media (min-width: 1280px) {
		font-size: 16px;
	}
`

/**
 * We now use an uncontrolled textarea since before the ItemAddition.comment.text update was causing the MenuItemPageSections to re-render 4x !
 *
 * Now with a ref, this re-render bug doesn't happen.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Comments = (props) => {
	const inputRef = useRef(null)

	useEffect(() => {
		const initialValue = props.commentOwner?.comment?.text || ''
		inputRef.current.value = initialValue

		if (props.itemId) {
			props.commentOwner.setComment(initialValue, props.itemId)
		} else {
			props.commentOwner.setComment(initialValue)
		}
	}, [])

	const changeHandler = (e) => {
		if (props.itemId) {
			props.commentOwner.setComment(e.target.value, props.itemId)
		} else {
			props.commentOwner.setComment(e.target.value)
		}
	}

	return (
		<CommentsRoot>
			<StyledTypographyTitle>Add Comments</StyledTypographyTitle>

			<StyledTextArea minRows="4" placeholder="Type any special instructions." maxLength={170} onChange={changeHandler} ref={inputRef} />
		</CommentsRoot>
	)
}

const compare = (prevProps, nextProps) => {
	if (prevProps.comment === nextProps.comment) {
		console.log(`1. not rendering Comments`)
		return true
	}
	console.log(`2. rendering Comments`)
	return false
	// return (prevProps.location.pathname !== '/' && nextProps.location.pathname !== '/')
}

export default React.memo(Comments, compare)

Comments.propTypes = {
	commentOwner: PropTypes.object,
}
