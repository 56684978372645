import { getTranslatedTextByKey } from 'utils/utils'
import type { Availability, Exception, WeeklyAvailability } from 'types/Coupons'
import type { LanguageLocale } from 'utils/language'
import Hour from './hour'
import SmartRange from './smart-range'
import { Day } from './types'
import type { DateString, Exceptions, FormattedException, FormattedWeek, HourString, FormattedAvailability } from './types'

export type { FormattedException, FormattedWeek, Range, FormattedAvailability } from './types'
export { SmartRange, Hour, Day }

const dateToHour = (date: Date): Hour => new Hour(`${date.getHours()}:${date.getMinutes()}` as HourString)

export const formatWeek = (weekly: WeeklyAvailability[]): FormattedWeek => {
	const result: FormattedWeek = {} as FormattedWeek
	;(Object.values(Day) as Day[])
		.filter((day) => !Number.isNaN(+day))
		.forEach((day) => {
			result[day] = new SmartRange()
		})

	const normaliseOne = ({ minuteOfWeek, durationMins }: WeeklyAvailability): void => {
		const MS_IN_SECONDS = 1000
		const SECONDS_IN_MINUTE = 60
		const MINUTES_IN_HOUR = 60
		const HOURS_IN_DAY = 24
		const MINUTES_IN_DAY = MINUTES_IN_HOUR * HOURS_IN_DAY

		const fullDay = minuteOfWeek / HOURS_IN_DAY / MINUTES_IN_HOUR
		const day: Day = Math.floor(fullDay)
		const startHour = Math.floor((minuteOfWeek / MINUTES_IN_HOUR) % HOURS_IN_DAY)
		const startMinute = (minuteOfWeek % MINUTES_IN_DAY) % MINUTES_IN_HOUR
		const startDate = new Date(2024, 1, day, startHour, startMinute)

		const endDate = new Date(startDate.getTime() + durationMins * SECONDS_IN_MINUTE * MS_IN_SECONDS)

		if (endDate.getDate() === startDate.getDate()) {
			result[day].add({
				start: new Hour(`${startHour}:${startMinute}` as HourString),
				end: dateToHour(endDate),
			})
		} else {
			// This is a multi-day range, we need to split it into daily ranges

			result[day].add({ start: new Hour(`${startHour}:${startMinute}` as HourString), end: new Hour('23:59') })
			for (let i = 0; i < Math.floor(durationMins / MINUTES_IN_DAY); i++) {
				console.assert(result[(day + i) as Day], `Day: ${day}, i: ${i}`)
				result[(day + i) as Day].add({ start: new Hour('00:00'), end: new Hour('23:59') })
			}

			if (endDate.getHours() !== 0 || endDate.getMinutes() !== 0) {
				result[endDate.getDay() as Day].add({
					start: new Hour('00:00'),
					end: dateToHour(endDate),
				})
			}
		}
	}

	weekly.forEach(normaliseOne)

	return Object.entries(result)
		.filter(([_, range]) => !range.isEmpty())
		.reduce<FormattedWeek>((acc, [day, range]) => ({ ...acc, [day]: range }), {} as FormattedWeek)
}

const formatDate = (date: Date): DateString => {
	const [month, day, year] = date.toLocaleDateString('en-US').split('/')

	return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}` as DateString
}

const handleSingleDayException = (exception: Exception): FormattedException => {
	if (exception.end.getHours() === 0 && exception.end.getMinutes() === 0) {
		exception.end = new Date(exception.end.getFullYear(), exception.end.getMonth(), exception.end.getDate(), exception.end.getHours(), -1)
	}

	const range = new SmartRange({
		start: dateToHour(exception.start),
		end: dateToHour(exception.end),
	}) as FormattedException

	range.comment = exception.comment
	range.available = exception.available

	return range
}

function getStartOfDay(date: Date): Date {
	const startOfDay = new Date(date)
	startOfDay.setHours(0, 0, 0, 0)

	return startOfDay
}

function getEndOfDay(date: Date): Date {
	const endOfDay = new Date(date)
	endOfDay.setHours(23, 59, 59, 999)

	return endOfDay
}

export function formatExceptions(exceptions: Exception[]): Exceptions {
	const result: Exceptions = {} as Exceptions

	function addOrSet(date: Date, exception: Exception): void {
		if (!result[formatDate(date)]) {
			result[formatDate(date)] = handleSingleDayException(exception)
		} else {
			const existingRange = result[formatDate(date)] as FormattedException
			if (exception.available === existingRange.available) {
				existingRange.add({ start: dateToHour(exception.start), end: dateToHour(exception.end) })
			} else {
				existingRange.remove({ start: dateToHour(exception.start), end: dateToHour(exception.end) })

				if (existingRange.isEmpty()) {
					delete result[formatDate(date)]
				}
			}
		}
	}

	exceptions.forEach((exception) => {
		if (exception.end.getHours() === 0 && exception.end.getMinutes() === 0) {
			exception.end = new Date(exception.end.getFullYear(), exception.end.getMonth(), exception.end.getDate(), exception.end.getHours(), -1)
		}

		const amountOfDays = Math.floor((exception.end.getTime() - getStartOfDay(exception.start).getTime()) / (1000 * 60 * 60 * 24))

		if (amountOfDays === 0) {
			addOrSet(exception.start, exception)
			return
		}

		for (let i = 0; i < amountOfDays; i++) {
			let date = new Date(exception.start.getTime())
			date.setDate(date.getDate() + i)

			if (i > 0) {
				date = getStartOfDay(date)
			}

			addOrSet(date, {
				...exception,
				start: date,
				end: getEndOfDay(date),
			})
		}

		addOrSet(exception.end, { ...exception, start: getStartOfDay(exception.end), end: exception.end })
	})

	return result
}

function dayToString(day: Day, locale: LanguageLocale = 'en_US', weekday: 'long' | 'short' | 'narrow' = 'short') {
	const { format } = new Intl.DateTimeFormat(locale.replace('_', '-'), { weekday })

	return format(new Date(Date.UTC(2021, 5, day - 1)))
}

const isFullDay = (range: SmartRange): boolean => range.equals(new SmartRange({ start: new Hour('0:0'), end: new Hour('23:59') }))

const getConsecutive = <T>(
	items: T[],
	{
		getNext,
		toString,
		equals = (a, b) => a === b,
		join: maybeJoin,
	}: {
		getNext: (item: T) => T
		toString: (item: T) => string
		equals?: (a: T, b: T) => boolean
		join?: (items: T[]) => string | null
	}
): string[] =>
	items.reduce<string[]>((acc, curr, index) => {
		const join = (prefix: string, itemsToJoin: T[]): string => {
			if (!maybeJoin || (index < items.length - 1 && equals(curr, getNext(curr)))) {
				return prefix
			}

			const joined = maybeJoin(itemsToJoin)

			if (!joined) {
				return prefix
			}

			return `${prefix}: ${joined}`
		}

		const prev = items[index - 1]
		const isConsecutive = prev !== undefined && equals(curr, getNext(prev)) && equals(curr, prev)

		if (isConsecutive) {
			const currentSequence = acc[acc.length - 1].split(' - ')
			currentSequence[1] = toString(curr)
			acc[acc.length - 1] = currentSequence.join(' - ')
			acc[acc.length - 1] = join(acc[acc.length - 1], items.slice(index - (currentSequence.length - 1), index + 1))
		} else {
			acc.push(join(toString(curr), [curr]))
		}

		return acc
	}, [])

export const weeklyToString = (week: FormattedWeek, locale: LanguageLocale): string[] => {
	const getNextDay = (day: Day): Day => (day + 1) % 7
	const days: Day[] = (Object.keys(week) as unknown as Day[]).map(Number)

	return getConsecutive(days, {
		getNext: getNextDay,
		toString: (item) => dayToString(item, locale),
		equals: (a, b) => !!week[a] && !!week[b] && week[a].equals(week[b]),
		join: ([day]) => {
			if (isFullDay(week[day])) {
				return null
			}

			return week[day]?.toString() ?? null
		},
	})
}

const formatFullExceptionDays = (exceptions: Exceptions): string[] => {
	const getNextDate = (date: DateString): DateString => {
		const currentDate = new Date(date)
		const nextDate = new Date(currentDate)
		nextDate.setDate(nextDate.getDate() + 1)
		return formatDate(nextDate)
	}

	const fullDays = (Object.entries(exceptions) as [DateString, FormattedException][]).filter(([date]) => isFullDay(exceptions[date]!)).sort()
	const availableDates = fullDays.filter(([_, range]) => range.available).map(([date]) => date)
	const unavailableDates = fullDays.filter(([_, range]) => !range.available).map(([date]) => date)

	const equals = (a: DateString, b: DateString) =>
		!!exceptions[a] && !!exceptions[b] && exceptions[a]?.equals(exceptions[b]) && exceptions[a].available === exceptions[b].available

	return getConsecutive(availableDates, {
		getNext: getNextDate,
		toString: String,
		equals,
	})
		.map((x) => `${x}: available`)
		.concat(
			getConsecutive(unavailableDates, {
				getNext: getNextDate,
				toString: String,
				equals,
			}).map((x) => `${x}: unavailable`)
		)
}

export function formatAvailability(availability: Availability, locale: LanguageLocale): FormattedAvailability {
	const formattedWeek = formatWeek(availability.weekly)

	const weekly: string[] = weeklyToString(formattedWeek, locale)

	const formattedExceptions = formatExceptions(availability.exceptions)

	const exceptions = Object.entries(formattedExceptions)
		.filter(([_, range]) => !isFullDay(range!))
		.map(([date, range]) => {
			const available = range?.available ? 'available' : 'unavailable'

			return `${date}: ${range!.toString()} ${getTranslatedTextByKey(`eCommerce.coupons.availability.exception.${available}`, available)}`
		})
		.concat(formatFullExceptionDays(formattedExceptions))
	return { weekly, exceptions }
}
