import { sanitizeStoreId, sendRequest } from '../../utils'
import Infra from 'mobx/Infra'
import { sendCustomEvent } from '../../analytics/analytics'
import { getCurrentDateByTimezone } from 'utils/timeUtils'
import { filterItemsAndSections } from 'utils/api/loadMenu/filterItems'
import { APPID_TO_CHANNEL_MAP } from 'utils/constants'
import type { ChannelType } from '../../../types/ChannelType'
import { isChannelTypeValid } from '../../../types/ChannelType'
import type { OrderType, OrderTypeFromQS } from 'types/OrderType'
import { isOrderTypeValid } from 'types/OrderType'
import type { Menu } from '../../../types/Menu'
import { isMenuValid } from '../../../types/Menu'

const filterMenu = ({ menu, orderType, channelType, date }: { menu: Menu; orderType: OrderType; channelType: ChannelType; date: string }) => {
	const { filteredItems, filteredSections } = filterItemsAndSections({
		items: menu.items,
		sections: menu.sections,
		hiddenSections: menu.hiddenSections || [],
		date,
		orderType,
		channelType,
	})

	// We do not filter upsells since they won't be chosen if they are not in the menu

	return {
		...menu,
		items: filteredItems,
		sections: filteredSections,
	}
}

function shouldVariationBeHidden(variation: any) {
	const isSingleSelect =
		(variation.displayType === 'choice' || variation.displayType === 'diff') &&
		variation.maxNumAllowed === 1 &&
		variation.title?.quantityselection !== 'true'
	const isMandatory = variation.minNumAllowed === 1
	const hasOneOption = variation.itemIds && variation.itemIds.length === 1

	return isSingleSelect && isMandatory && hasOneOption
}

const fixAndFilterVariationsHotfix = (myMenu: Menu): void => {
	const itemsIds = Object.keys(myMenu.items)
	itemsIds.forEach((itemId) => {
		const item = myMenu.items[itemId]
		if (item.variations && item.variations[0]) {
			item.variations.forEach((variation) => {
				variation.hideVariation = shouldVariationBeHidden(variation)

				if (variation.itemIds && variation.itemIds[0]) {
					// items
					variation.itemIds = variation.itemIds.filter((choiceId) => !!myMenu.items[choiceId])

					// defaults
					if (variation.defaults && variation.defaults[0]) {
						variation.defaults = variation.defaults.filter((defaultId) => !!myMenu.items[defaultId])
					}

					// prices
					if (variation.prices) {
						const variationPrices = Object.keys(variation.prices)
						if (variationPrices && variationPrices[0]) {
							variationPrices.forEach((variationPriceItemId) => {
								if (!myMenu.items[variationPriceItemId]) {
									delete variation.prices?.[variationPriceItemId]
								}
							})
						}
					}

					// quantities
					if (variation?.title?.quantityselection === 'true') {
						// we have the new and the old versions of quantity selection
						if (variation.maxNumAllowed) {
							variation.maxNumAllowed -= 1
						}
						if (variation.minNumAllowed) {
							variation.minNumAllowed -= 1
						}

						// check the new structure
						if (variation?.title?.itemOptions) {
							const quantityOptions = Object.keys(variation.title.itemOptions)
							if (quantityOptions && quantityOptions[0]) {
								quantityOptions.forEach((quantityOptionItemId) => {
									if (!myMenu.items[quantityOptionItemId]) {
										delete variation.title?.itemOptions?.[quantityOptionItemId]
									}
								})
							}
						}
					}

					// For single select with one mandatory but no default => we select it
					const isSingleSelect =
						(variation.displayType === 'choice' || variation.displayType === 'diff') &&
						variation.maxNumAllowed === 1 &&
						variation.title?.quantityselection !== 'true'
					const isMandatory = variation.minNumAllowed === 1
					const isHidden = variation.itemIds && variation.itemIds.length === 1
					const hasDefault = variation.defaults && variation.defaults.length > 0

					if (isSingleSelect && isMandatory && isHidden && (!hasDefault || variation.itemIds?.[0] !== variation.defaults?.[0])) {
						variation.defaults = [...variation.itemIds]
					}

					// For a quantity select v2 with minimum, one option and without a default => set it
					const isQuantitySelect = variation.title?.quantityselection === 'true'
					const isV2 = variation.title?.itemOptions

					if (isQuantitySelect && isV2) {
						const hasOneOption = variation.itemIds && variation.itemIds?.length === 1
						const isMandatory = variation.title?.minimumAmountRequired && variation.title.minimumAmountRequired > 0

						if (hasOneOption && isMandatory) {
							const itemId = variation.itemIds?.[0]
							const options = variation?.title?.itemOptions?.[itemId] ?? {}
							// @ts-ignore
							const isDefaultSet = options.defaultAmount && options.defaultAmount >= variation.title?.minimumAmountRequired

							if (!isDefaultSet) {
								// @ts-ignore
								variation.title.itemOptions[itemId].defaultAmount = variation.title?.minimumAmountRequired
							}
						}
					}
				}
			})
		}
	})
}

/**
 * Function fetchMenu fetches the menu from the server for a given chain or store
 */
const fetchMenu = async (chainId: string, storeId: string, stopLoading = true): Promise<Menu | null> => {
	// if no store id since someone tried to access /menu/ from URL without params (from google for example)
	// redirect back to the menu since no menu can be loaded
	if (!storeId) {
		window.location.href = '/home'
	}

	// useStorefrontMenuUrl support is removed as part of CORE-1518 - Once it's brought back this can be uncommented
	//	if (Infra?.appParams?.features?.useStorefrontMenuUrl) {
	//		const storefrontEndpoint = `${Infra?.appParams?.storefrontUrl}/v1/store-snapshots/${sanitizeStoreId(storeId)}`
	//		outgoingMenuRequest = () => sendRequest(true, storefrontEndpoint, 'get', null, null, stopLoading, 90000, null, false)
	//	} else {
	if (!storeId?.includes('dev')) {
		storeId = process.env.NODE_ENV === 'production' || Infra?.appParams?.useProductionMenu ? storeId : `${storeId}_dev`
	}

	if (!storeId?.includes('dev') && typeof process !== 'undefined' && Infra?.appParams?.environment !== 'PROD') {
		storeId = `${storeId}_dev`
	}

	const outgoingMenuRequest = () =>
		sendRequest(true, `https://cdn.tictuk.com/${chainId}/menus/${storeId}_menu.json`, 'get', null, null, stopLoading)

	try {
		if (chainId && storeId) {
			return (await outgoingMenuRequest()) as unknown as Menu
		}

		console.error(`Url missing 'j' and/or 'pc' params so cannot load the menu`)
		sendCustomEvent({
			category: 'errors',
			action: 'load data',
			label: 'missing j or pc param',
		})
	} catch (e) {
		console.error(e)
		sendCustomEvent({
			category: 'errors',
			action: 'load data',
			label: 'store',
		})
	}

	return null
}

/**
 * Function loadMenu fetches the menu for a given chain or store and filters sections and items
 */
const loadMenu = async (args: {
	chainId: string
	storeId: string
	orderTypeFromQS: OrderTypeFromQS
	appId: number
	stopLoading: boolean
}): Promise<Menu | null> => {
	const { chainId, storeId, orderTypeFromQS, appId, stopLoading } = args
	const menu = await fetchMenu(chainId, storeId, stopLoading)
	const orderType = orderTypeFromQS === '-class' ? 'all' : orderTypeFromQS?.split('-')?.[0] || 'all'
	const channelType = APPID_TO_CHANNEL_MAP[appId as keyof typeof APPID_TO_CHANNEL_MAP]

	if (!isMenuValid(menu)) {
		console.error(`Menu is not valid, got ${menu}, for chainId ${chainId}, storeId ${storeId}, and env ${Infra?.appParams?.environment}`)
		return null
	}

	if (!isOrderTypeValid(orderType)) {
		console.error(`Order type not valid, got ${orderType}`)
		return null
	}

	if (!isChannelTypeValid(channelType)) {
		console.error(`channelType is not valid, got ${channelType}`)
		return null
	}

	// args.storage.setStoreId(menu.id)
	localStorage.setItem('storeId', menu.id)

	const filteredMenu = filterMenu({ menu, orderType, channelType, date: getCurrentDateByTimezone(menu.timezone).toUTCString() })

	fixAndFilterVariationsHotfix(filteredMenu as unknown as Menu)

	return filteredMenu
}

export { filterMenu, fetchMenu }
export default loadMenu
