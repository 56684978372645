// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { inject, observer } from 'mobx-react'
import SideMenuHeader from './SideMenuHeader'
import SideMenuLanguageSelector from './SideMenuLanguageSelector'
import SideMenuBottom from './SideMenuBottom'
import SideMenuSocialSectionBase from './SideMenuSocialSection'
import SideMenuStoreSection from './SideMenuStoreSection'
import { StoreContext } from 'contexts/StoreContext'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import TypographyPro from '../../../themes/TypographyPro'
import IconComponent from '../../../themes/IconComponent'
import Version from 'pages/Version'
import { useTheme } from 'styled-components'
import SmartLink from '../../../static/components/SmartLink'
import { ORDER_TYPES } from 'utils/constants'
import { useRouter } from 'next/router'
import { useStores } from 'hooks/useStores'

const SideMenuSocialSection = styled(SideMenuSocialSectionBase)`
	margin: 44px 0 30px;
`

const ArrowIcon = (props) => <IconComponent asset="/icons/rightArrow.svg" {...props} />

const MenuWrapper = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: #000c;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0;
	animation: ${(props) => (props.isClosing ? 'fade-out 0.3s forwards' : 'fade-in 0.2s forwards')};
	z-index: 1000000;
`

const Menu = styled.div`
	width: 265px;
	height: 100vh;
	background-color: white;
	opacity: 1;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000010;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: stretch;
	padding: 10px 10px;
	animation: ${(props) => (props.isClosing ? 'mobile-menu-disappears 0.3s forwards' : 'mobile-menu-appears 0.3s forwards')};
	transition-timing-function: ease-out;
	overflow-y: scroll;
`

const MenuTop = styled.div`
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: stretch;
`

const MenuBottom = styled.div`
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	align-items: stretch;
`

const MenuStoreTitle = styled(TypographyPro)`
	color: var(--primary);
	//padding: 16.5px 0;
`

const StyledArrowIcon = styled(ArrowIcon)`
	width: 12px;
	height: 12px;
	transform: ${(props) => (props.expanded ? 'rotateZ(90deg)' : 'rotateZ(0deg)')};
	transition: transform 0.3s;
`

const LinkContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
`

const StyledSection = styled.section`
	border-top: 1px solid #e9ebee;
	border-bottom: 1px solid #e9ebee;
	padding: 22.5px 0;
`

const StyledVersion = styled(TypographyPro)`
	align-self: center;
`

const Link = styled.a`
	text-decoration: none;
`

const SideMenu = inject(
	'Infra',
	'Application',
	'AddressManager',
	'User',
	'Home'
)(
	observer((props) => {
		const router = useRouter()
		const { Infra, Application, AddressManager, User, Home } = props
		const theme = useTheme()
		const { cartStore } = useStores()

		const skeletonData = Infra.eCommerceFooter
		const isEcommerce = [true, 'true'].includes(Infra.appParams.eCommerce)

		const { store, setStore } = useContext(StoreContext)
		const restStore = store?.data

		const doOpeningHoursExist = !!restStore && !!restStore.openingHours
		const doesMetaDataExist = !(store?.metaData === null)
		const deliveryInfo = doesMetaDataExist
			? (restStore?.deliveryInfo ?? []).length > (store?.metaData?.deliveryInfo ?? []).length
				? restStore?.deliveryInfo
				: store?.metaData?.deliveryInfo
			: restStore?.deliveryInfo
		const availableDeliveryAreas = (deliveryInfo ?? []).filter((item) => item.type === ORDER_TYPES.DELIVERY) || []
		const doDeliveryAreasExist = availableDeliveryAreas.length > 0
		const isStoreDisplayed = doOpeningHoursExist || doDeliveryAreasExist

		const [isCLosing, setIsClosing] = useState(false)
		const [secretCounterClick, setSecretCounterClick] = useState(0)

		useEffect(() => {
			if (Application.isMobileApp && secretCounterClick >= 10) {
				const tokenAlert = `LS - ${localStorage.getItem('pushNotificationsToken')} - status ${localStorage.getItem(
					'pushNotificationsStatus'
				)}`
				const urlAlert = `URL: ${location.origin}`
				const userConsistentIdAlert = `userConsistentId: ${localStorage.getItem('userConsistentId')}`
				const pushNotificationsDeclineDate = localStorage.getItem('pushNotificationsDeclineDate')
				const pushNotificationsDeclineDateAlert = `pushNotificationsDeclineDate: ${
					pushNotificationsDeclineDate ? new Date(parseInt(pushNotificationsDeclineDate)) : 'null'
				}`
				alert(`${urlAlert}\n${tokenAlert}\n${pushNotificationsDeclineDateAlert}\n${userConsistentIdAlert}`)
			}
		}, [secretCounterClick])

		useEffect(() => {
			const targetElement = document.getElementById('mobile-app-menu')
			disableBodyScroll(targetElement, {
				reserveScrollBarGap: true,
			})
			return () => {
				enableBodyScroll(targetElement)
			}
		}, [])

		const isGeneratedStatically = !Application.isMobileApp

		const closeMenu = () => {
			setIsClosing(true)
			// Needed so closing animation can happen
			setTimeout(() => {
				setIsClosing(false)
				Infra.toggleMobileMenu(false)
			}, 320)
		}

		return (
			<>
				<MenuWrapper isClosing={isCLosing} onClick={() => closeMenu()} />
				<Menu
					id="mobile-app-menu"
					isClosing={isCLosing}
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					<MenuTop data-testid="side-menu-top">
						<SideMenuHeader headerProps={props.headerProps} closeMenu={() => closeMenu()} Infra={Infra} />
						<SideMenuLanguageSelector />
						{skeletonData?.header &&
							skeletonData?.header.sections?.map((section, key) => {
								if (!Home.areAllStoresClosed() && section.path.includes('menu') && AddressManager.isUserLocalized()) {
									const onMenuClick = async (e) => {
										e.preventDefault()

										const { menuRes, storeMetaData, serverSession, menuUrl } = await AddressManager.onMenuClickWhenLocalized(
											cartStore,
											User
										)
										await User.setSession(serverSession)

										setStore((store) => ({ ...store, data: menuRes, metaData: storeMetaData }))

										router.push(`${menuUrl}`)
									}

									return (
										<Link href="#" onClick={onMenuClick}>
											<StyledSection>
												<LinkContainer>
													<MenuStoreTitle
														variant="h2"
														data-testid={`${section.path?.slice(section.path.lastIndexOf('/') + 1)}-page-link`}
													>
														{section.text}
													</MenuStoreTitle>
													<StyledArrowIcon />
												</LinkContainer>
											</StyledSection>
										</Link>
									)
								}

								return (
									<SmartLink
										sectionItem={section}
										key={key}
										isGeneratedStatically={isGeneratedStatically}
										callbackClick={closeMenu}
										openInSameBrowserTab={section.itemType !== 'external'}
									>
										<StyledSection>
											<LinkContainer>
												<MenuStoreTitle
													variant="h2"
													data-testid={`${section.path?.slice(section.path.lastIndexOf('/') + 1)}-page-link`}
												>
													{section.text}
												</MenuStoreTitle>
												<StyledArrowIcon />
											</LinkContainer>
										</StyledSection>
									</SmartLink>
								)
							})}
						{isStoreDisplayed && (
							<SideMenuStoreSection
								doOpeningHoursExist={doOpeningHoursExist}
								doDeliveryAreasExist={doDeliveryAreasExist}
								deliveryInfo={deliveryInfo ?? []}
							/>
						)}
						<SideMenuBottom skeletonData={skeletonData} isEcommerce={isEcommerce} isGeneratedStatically={isGeneratedStatically} />
					</MenuTop>
					<MenuBottom>
						{isEcommerce && <SideMenuSocialSection skeletonData={skeletonData} isMobileApp={Application.isMobileApp} />}
						<StyledVersion component="div" variant="BodySmall" onClick={() => setSecretCounterClick((ps) => ps + 1)}>
							<Version color={theme.palette.disable} />
						</StyledVersion>
					</MenuBottom>
				</Menu>
			</>
		)
	})
)
export default SideMenu
