import { createGlobalStyle } from 'styled-components/macro'
import { css } from 'styled-components'

// todo: add a description of the process

export type Brand = 'pizzahut' | 'tacobell' | 'kfc' | 'telepizza'

type RGBAColor = string

type CSSShadow = string

type CSSBorderRadius = string

type CSSFontFamily = string

export interface CSSFontStyling {
	fontWeight: string
	fontSize: string
	lineHeight: string
	letterSpacing: string
	textTransform: string
	fontFamily: CSSFontFamily
	textDecoration: string
	'@media (max-width:576px)'?: CSSFontStyling
}

export type TypographyVariants =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'Subhead'
	| 'SubheadHighlighted'
	| 'BodyRegularHighlighted' // This should be used instead of "BodyHighlighted"
	| 'BodyRegular'
	| 'Price'
	| 'BodyRegularClickable'
	| 'BodyRegularStrikethrough' // CURRENTLY NOT IN USE YET
	| 'BodySmall'
	| 'BodySmallHighlighted'
	| 'BodySmallClickable' // CURRENTLY NOT IN USE YET
	| 'CTABig'
	| 'CTASmall'
	| 'CTAText' // NAME HAS TO BE CHANGED TO "CTATextBig"
	| 'CTATextBig' // USE IT INSTEAD OF "CTAText"
	| 'CTATextSmall' // CURRENTLY NOT IN USE YET
	| 'HomePageTitle'

export interface Theme {
	name: string
	brand?: Brand // After applying to all markets on production we can remove ? sign
	palette: {
		first: RGBAColor
		second: RGBAColor
		footerAndDarkBackgrounds: RGBAColor
		strokeMenuTitle: RGBAColor
		highlights: RGBAColor
		fonts: RGBAColor
		inactive: RGBAColor
		disable: RGBAColor
		optionalBackground: RGBAColor
		approved: RGBAColor
		err: RGBAColor
		clear: RGBAColor
		localizationActive: RGBAColor
		localizationInactive: RGBAColor
		counterCircleBg: RGBAColor
		cartCounterFont: RGBAColor
		menuItemCounterFont: RGBAColor
		menuItemCartIcon: RGBAColor
		menuItemCartCirclebg: RGBAColor // legacy: should be removed by "menuItemCartCircleBg"
		menuItemCartCircleBg: RGBAColor // Replaces "menuItemCartCirclebg" with typo
		menuItemCartBg: RGBAColor
		menuItemCartBgEvent: RGBAColor
		price: RGBAColor
		priceStrikethroug: RGBAColor // legacy: should be removed by "priceStrikeThrough"
		priceStrikeThrough: RGBAColor // Replaces "priceStrikethroug" with typo
		favoritesUnselected: RGBAColor
		successBg: RGBAColor
		errorBg: RGBAColor
		snacksbarSuccessFont: RGBAColor // legacy: should be removed by "snacksBarSuccessFont"
		snacksBarSuccessFont: RGBAColor // Replaces "snacksbarSuccessFont" with typo
		plainNotificationBg: RGBAColor
		plainNotificationFont: RGBAColor
		pushNotificationBg: RGBAColor
		pushNotificationFont: RGBAColor
		warningBg: RGBAColor
		warningText: RGBAColor
		couponBg: RGBAColor
		infoBg: RGBAColor
		mapBorder: RGBAColor
		mapIcon: RGBAColor
		topperBtnFontColor: RGBAColor
		topperBtnColor: RGBAColor
		accountIconSignedIn: RGBAColor
		accountIconWithoutSignedIn: RGBAColor
		headerLinkHover: RGBAColor
		headerLinkClicked: RGBAColor
		topMenuLine: RGBAColor
		useMyCurrentLocationFont: RGBAColor
		filterSelectionSelected: RGBAColor
		homePageTitle: RGBAColor
		carouselArrBg: RGBAColor
		homePageDivider: RGBAColor
		appBotNavActiveBg: RGBAColor
		appBotNavActiveFontAndIcon: RGBAColor
		appBotNavInactiveFontAndIcon: RGBAColor
		brandTitle: RGBAColor
	}
	shadow: {
		defaultShadow1: CSSShadow
		defaultShadow2: CSSShadow
		highlightShadow1: CSSShadow
		highlightShadow2: CSSShadow
		innerShadow: CSSShadow
		botNavShadow: CSSShadow
	}
	borderRadius: {
		borderRadiusFrame: CSSBorderRadius
		borderRadiusBtn: CSSBorderRadius
		borderRadiusInput: CSSBorderRadius
	}
	options: {
		cartBadge: {
			desktop: {
				top: number
				right: number
			}
			mobile: {
				top: number
				right: number
			}
		}
	}
	typography: { fontFamily: string } & Record<TypographyVariants, CSSFontStyling>
}

export const GlobalStyle = createGlobalStyle`
  body {
	  background-color: var(--clear);

    --primary: ${({ theme }: { theme: Theme }) => theme.palette.first};
    --secondary: ${({ theme }: { theme: Theme }) => theme.palette.second};
    --footerAndDarkBackgrounds: ${({ theme }: { theme: Theme }) => theme.palette.footerAndDarkBackgrounds};
    --strokeMenuTitle: ${({ theme }: { theme: Theme }) => theme.palette.strokeMenuTitle};
    --highlights: ${({ theme }: { theme: Theme }) => theme.palette.highlights};
    --fonts: ${({ theme }: { theme: Theme }) => theme.palette.fonts};
    --inactive: ${({ theme }: { theme: Theme }) => theme.palette.inactive};
    --disable: ${({ theme }: { theme: Theme }) => theme.palette.disable};
    --optionalBackground: ${({ theme }: { theme: Theme }) => theme.palette.optionalBackground};
    --approved: ${({ theme }: { theme: Theme }) => theme.palette.approved};
    --err: ${({ theme }: { theme: Theme }) => theme.palette.err};
    --clear: ${({ theme }: { theme: Theme }) => theme.palette.clear};
    --localizationActive: ${({ theme }: { theme: Theme }) => theme.palette.localizationActive};
    --localizationInactive: ${({ theme }: { theme: Theme }) => theme.palette.localizationInactive};
    --counterCircleBg: ${({ theme }: { theme: Theme }) => theme.palette.counterCircleBg};
    --cartCounterFont: ${({ theme }: { theme: Theme }) => theme.palette.cartCounterFont};

    // New colors
    --menuItemCounterFont: ${({ theme }: { theme: Theme }) => theme.palette.menuItemCounterFont};
    --menuItemCartIcon: ${({ theme }: { theme: Theme }) => theme.palette.menuItemCartIcon};
    --menuItemCartCirclebg: ${({ theme }: { theme: Theme }) => theme.palette.menuItemCartCirclebg};
    --menuItemCartBg: ${({ theme }: { theme: Theme }) => theme.palette.menuItemCartBg};
    --menuItemCartBgEvent: ${({ theme }: { theme: Theme }) => theme.palette.menuItemCartBgEvent};
    --price: ${({ theme }: { theme: Theme }) => theme.palette.price};
    --priceStrikethroug: ${({ theme }: { theme: Theme }) => theme.palette.priceStrikethroug};
    --favoritesUnselected: ${({ theme }: { theme: Theme }) => theme.palette.favoritesUnselected};
    --successBg: ${({ theme }: { theme: Theme }) => theme.palette.successBg};
    --errorBg: ${({ theme }: { theme: Theme }) => theme.palette.errorBg};
    --snacksbarSuccessFont: ${({ theme }: { theme: Theme }) => theme.palette.snacksbarSuccessFont};
    --plainNotificationBg: ${({ theme }: { theme: Theme }) => theme.palette.plainNotificationBg};
    --plainNotificationFont: ${({ theme }: { theme: Theme }) => theme.palette.plainNotificationFont};
    --pushNotificationBg: ${({ theme }: { theme: Theme }) => theme.palette.pushNotificationBg};
    --pushNotificationFont: ${({ theme }: { theme: Theme }) => theme.palette.pushNotificationFont};
    --warningBg: ${({ theme }: { theme: Theme }) => theme.palette.warningBg};
	--warningText: ${({ theme }: { theme: Theme }) => theme.palette.warningText};
    --couponBg: ${({ theme }: { theme: Theme }) => theme.palette.couponBg};
    --infoBg: ${({ theme }: { theme: Theme }) => theme.palette.infoBg};
    --mapBorder: ${({ theme }: { theme: Theme }) => theme.palette.mapBorder};
    --mapIcon: ${({ theme }: { theme: Theme }) => theme.palette.mapIcon};
    --topperBtnFontColor: ${({ theme }: { theme: Theme }) => theme.palette.topperBtnFontColor};
    --topperBtnColor: ${({ theme }: { theme: Theme }) => theme.palette.topperBtnColor};
    --accountIconSignedIn: ${({ theme }: { theme: Theme }) => theme.palette.accountIconSignedIn};
    --accountIconWithoutSignedIn: ${({ theme }: { theme: Theme }) => theme.palette.accountIconWithoutSignedIn};
    --headerLinkHover: ${({ theme }: { theme: Theme }) => theme.palette.headerLinkHover};
    --headerLinkClicked: ${({ theme }: { theme: Theme }) => theme.palette.headerLinkClicked};
    --topMenuLine: ${({ theme }: { theme: Theme }) => theme.palette.topMenuLine};
    --useMyCurrentLocationFont: ${({ theme }: { theme: Theme }) => theme.palette.useMyCurrentLocationFont};
    --filterSelectionSelected: ${({ theme }: { theme: Theme }) => theme.palette.filterSelectionSelected};
    --homePageTitle: ${({ theme }: { theme: Theme }) => theme.palette.homePageTitle};
    --carouselArrBg: ${({ theme }: { theme: Theme }) => theme.palette.carouselArrBg};
    --homePageDivider: ${({ theme }: { theme: Theme }) => theme.palette.homePageDivider};
    --appBotNavActiveBg: ${({ theme }: { theme: Theme }) => theme.palette.appBotNavActiveBg};
    --appBotNavActiveFontAndIcon: ${({ theme }: { theme: Theme }) => theme.palette.appBotNavActiveFontAndIcon};
    --appBotNavInactiveFontAndIcon: ${({ theme }: { theme: Theme }) => theme.palette.appBotNavInactiveFontAndIcon};
    --brandTitle: ${({ theme }: { theme: Theme }) => theme.palette.brandTitle};

    // shadows
    --defaultShadow1: ${({ theme }: { theme: Theme }) => theme.shadow?.defaultShadow1};
    --defaultShadow2: ${({ theme }: { theme: Theme }) => theme.shadow?.defaultShadow2};
    --highlightShadow1: ${({ theme }: { theme: Theme }) => theme.shadow?.highlightShadow1};
    --highlightShadow2: ${({ theme }: { theme: Theme }) => theme.shadow?.highlightShadow2};
    --innerShadow: ${({ theme }: { theme: Theme }) => theme.shadow?.innerShadow};
    --botNavShadow: ${({ theme }: { theme: Theme }) => theme.shadow?.botNavShadow};

    // border-radius
    --borderRadiusFrame: ${({ theme }: { theme: Theme }) => theme.borderRadius?.borderRadiusFrame};
    --borderRadiusBtn: ${({ theme }: { theme: Theme }) => theme.borderRadius?.borderRadiusBtn};
    --borderRadiusInput: ${({ theme }: { theme: Theme }) => theme.borderRadius?.borderRadiusInput};

    ${() =>
		process.env.NEXT_PUBLIC_HIDE_ERROR_MODAL === 'true' &&
		css`
			nextjs-portal {
				display: none;
			}
		`}
  }
`
