import { staticImplements } from 'utils/types'
import type {
	ServerSideCoupon,
	CouponsDependencies,
	PrbResponse,
	SearchBody,
	AddCouponToWalletResponse,
	GetCouponQuery,
	PrbCoupon,
	Feature,
	DateTimeObject,
} from './types'
import { sendRequest } from 'utils/utils'
import Infra from 'mobx/Infra'
import Application from 'mobx/Application'
import type { AxiosError, Method } from 'axios'
import Account from 'mobx/Account'
import CouponError, { ErrorCode } from './errors'
import type { Exception, Flags, Coupon } from 'shared-types/Coupon'
import { Channel, OrderType } from 'shared-types/Coupon'
import { IN_STORE_ORDER_TYPES } from 'types/Coupons'
import { CheckCouponOn } from '../CouponFlow'
import AddressManager from '../AddressManager'
import type { ShowSnackbarProps } from 'mobx/Infra/Infra.type'

@staticImplements<CouponsDependencies>()
export default class CouponsRepository {
	/**
	 * Sends a request to the Coupons API (Marketing Service) with the specified parameters
	 *
	 * @throws CouponError if the request fails. Attempts to extract the message and code from the response, but will default to error.message and ErrorCode.DEFAULT
	 *
	 */

	private static async fetch<T = void>(
		url: string,
		method: Method,
		body?: object,
		queryParams?: string | string[][] | Record<string, string> | URLSearchParams
	): Promise<T> {
		const baseUrl = `${Infra.appParams.wruec}/v1/tenants/${Infra.appParams.c}/`

		let fullUrl = `${baseUrl}${url}`

		if (queryParams) {
			const params = new URLSearchParams(queryParams)
			fullUrl += `?${params.toString()}`
		}

		const authorizationHeader = Account.user ? await Account.getAuthorizationHeader(Application.backendChannel) : null

		return (await sendRequest(false, fullUrl, method, body, authorizationHeader).catch(
			(e: AxiosError<{ error?: { message?: string; code?: ErrorCode } }>) => {
				const { error } = e.response?.data ?? {}
				const message = error?.message ?? e.message
				throw new CouponError(message, error?.code ?? ErrorCode.DEFAULT)
			}
		)) as T
	}

	private static isValidCoupon(maybeCoupon: unknown): maybeCoupon is ServerSideCoupon {
		return maybeCoupon != null && typeof maybeCoupon === 'object' && 'id' in maybeCoupon && 'features' in maybeCoupon
	}

	private static normaliseCoupon(coupon: ServerSideCoupon): Coupon {
		const { features, code, ...rest } = coupon
		let { availability, channels } = coupon

		// LOWERCASE ONLY
		const orderTypeMap: Record<string, OrderType> = {
			peakup: OrderType.PICKUP,
			instore: OrderType.IN_STORE,
			drivethru: OrderType.DRIVETHROUGH,
			drivethrough: OrderType.DRIVETHROUGH,
			autoexpress: OrderType.DRIVETHROUGH,
		}

		const flagsMap: Record<Feature['name'], keyof Flags> = {
			requireLoginWallet: 'requireLogin',
			displayInCouponWallet: 'wallet',
		}

		const orderTypes: OrderType[] = rest.orderTypes
			.map((type) => (type.toLowerCase() in orderTypeMap ? orderTypeMap[type.toLowerCase()] : type))
			.filter((type) => (Object.values(OrderType) as string[]).includes(type)) as OrderType[]

		const flags: Partial<Flags> = features
			.map((x) => ({ ...x, name: x.name in flagsMap ? flagsMap[x.name] : x.name }))
			.reduce((acc, { name, ...fields }) => ({ ...acc, [name]: { value: true, ...fields } }), {} as Partial<Flags>)

		const expiration = coupon.expiration == null ? undefined : new Date(coupon.expiration)
		const formattedExpiration = expiration && Intl.DateTimeFormat(Infra?.locale?.msg?.replace('_', '-') ?? 'en-US').format(expiration)
		const attachedAt = 'attachedAt' in coupon && typeof coupon.attachedAt === 'number' ? new Date(coupon.attachedAt) : undefined

		const dateTimeObjectToDate = ({ year, month, day, hour, minute }: DateTimeObject) => new Date(year, month, day, hour, minute)

		// Default to all channels
		if (!channels || channels.length === 0) {
			channels = Object.values(Channel)
		}

		// Default to always available
		if (!availability) {
			availability = {
				weekly: [
					{
						minuteOfWeek: 0,
						durationMins: 10080,
					},
				],
				exceptions: [],
			}
		}

		const exceptions =
			availability?.exceptions?.map(
				({ start, end, ...exception }): Exception => ({
					start: dateTimeObjectToDate(start),
					end: dateTimeObjectToDate(end),
					...exception,
				})
			) || []

		return {
			...rest,
			...(attachedAt && { attachedAt }),
			channels,
			availability: {
				...availability,
				exceptions,
			},
			code,
			orderTypes,
			flags,
			expiration,
			formattedExpiration,
			timestamp: new Date(),
		}
	}

	static isLocalized(): boolean {
		return AddressManager.isUserLocalized()
	}

	static async getMyCoupons(): Promise<Coupon[]> {
		const ignored = CouponsRepository.getIgnoreOrderTypes()
		const dynamicFeatures: SearchBody['fields'] = []

		if (IN_STORE_ORDER_TYPES.every((orderType) => ignored.includes(orderType))) {
			dynamicFeatures.push({ name: 'type', operator: 'in', value: ['individual', 'internal'], logicalOperator: 'and' })
		}

		const body: SearchBody = {
			fields: [
				{ name: 'relatedFeatures', operator: 'eq', value: 'displayInCouponWallet', logicalOperator: 'or' },
				{ name: 'relatedFeatures', operator: 'eq', value: 'showInMyCoupons', logicalOperator: 'or' },
				...dynamicFeatures,
			],
		}

		return (await this.fetch<ServerSideCoupon[]>('users/me/coupons/search', 'post', body))
			.map(this.normaliseCoupon)
			.map((coupon) => ({ ...coupon, flags: { ...coupon.flags, applied: { value: false } } }))
		// .map((coupon) => addVirtualFields(coupon))
	}

	static async getCoupon(code: string, checkCouponOn: CheckCouponOn): Promise<Coupon | null> {
		// TODO: Change this code to have a more abstracted implementation, for example, get the store ID from the parameters
		const storeId =
			checkCouponOn === CheckCouponOn.STORE && AddressManager.isUserLocalized() && localStorage.getItem('storeId')
				? localStorage.getItem('storeId')
				: null

		const queryParams: GetCouponQuery = {}

		if (storeId) {
			queryParams.storeId = storeId
		}

		return this.fetch<ServerSideCoupon | null>(`coupons/${code}`, 'get', {}, queryParams).then((coupon) => {
			if (coupon && typeof coupon === 'object' && Object.keys(coupon).length === 0) {
				return null
			}

			if (this.isValidCoupon(coupon)) {
				// return addVirtualFields(this.normaliseCoupon(coupon))
				const result = this.normaliseCoupon(coupon)
				if (storeId) {
					// Disable caching on store coupons in order to prevent a bug where
					// you could fetch a store-specific coupon while unlocalised
					result.timestamp = new Date(0)
				}

				return result
			}

			throw new CouponError('Received invalid coupon from response', ErrorCode.DEFAULT)
		})
	}

	/**
	 * Adds a coupon to the current user's wallet
	 *
	 * @throws if the user already has this coupon, if the coupon doesn't exist
	 */
	static async addCouponToWallet(code: string): Promise<AddCouponToWalletResponse> {
		return this.fetch('coupons', 'post', { code })
	}

	static async addCouponToWalletBulk(codes: string[]): Promise<AddCouponToWalletResponse[]> {
		return this.fetch('coupons/bulk', 'post', { codes })
	}

	static async getPrbCode(id: string): Promise<PrbCoupon> {
		console.count(`Fetching PRB code for ${id}`)
		return this.fetch<PrbResponse>(`coupons/${id}/GENERATE_QR`, 'patch')
			.then(({ couponCode, couponUrl }: PrbResponse): PrbCoupon => {
				if (!couponCode || !couponUrl || typeof couponUrl !== 'string' || typeof couponUrl !== 'string') {
					throw new CouponError('Error at PRB response', ErrorCode.USERS_COUPON_GENERATE_PRB_CODE)
				}

				return { code: couponCode, url: couponUrl }
			})
			.catch(() => {
				throw new CouponError('Error at PRB response', ErrorCode.USERS_COUPON_GENERATE_PRB_CODE)
			})
	}

	static getIgnoreOrderTypes(): OrderType[] {
		return (Infra?.appParams?.features?.disableCouponsOfOrderType as OrderType[]) ?? []
	}

	static showSnackbar = (data: ShowSnackbarProps) => {
		Infra.showSnackbar(data)
	}
}
