import type { NextPage, NextPageContext } from 'next'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { usePathname } from 'next/navigation'
import { useCompare } from 'hooks/useCompare'

import type { ReactElement, ReactNode } from 'react'
import { useState, useEffect, useMemo } from 'react'
// @ts-expect-error we're too lazy to define a module :(
import SmartBanner from 'react-smartbanner'
import { Provider } from 'mobx-react'
import styled from 'styled-components/macro'
import Cookies from 'js-cookie'
import { sendPageViewEvent } from 'utils/analytics/analytics'

import { CONSTANTS } from 'utils/constants'

import MenuItemOverlay from 'components/menu/MenuItemOverlay'
import CustomHead from '../components/CustomHead'
import { Footer } from 'mobileApp/Footer'

import type { Store } from 'types/StoreContext'
import { StoreContext } from 'contexts/StoreContext'
import { ReloadContext } from 'contexts/ReloadContext'
import type { LanguageCode } from 'utils/utils'
import { configAxios, getRandomUUID, isMobileApp, getTranslatedTextByKey, isiOS, handleMobileApplicationDeepLink, safeStringify } from 'utils/utils'

import Infra from 'mobx/Infra'
import Payment from 'mobx/Payment/Payment'
import User from 'mobx/User'
import Application from 'mobx/Application'
import AddressManager from 'mobx/AddressManager'
import Account from 'mobx/Account'
import MobileApplication from 'mobx/MobileApplication'
import ItemAdditions from 'mobx/ItemAdditions'
import Home from 'mobx/Home'

import ThemeProvider from '../components/themes/themeProvider'
import UnrecoverableServerError from '../components/errors/unrecoverableServerError'

import { appInit, buildCombinedTheme, extractQueryParamsFromUrl, extractUrlsFromNextRequest } from '../utils/nextUtils'
import { ReactNativeComms } from 'utils/reactNativeComms'
import nextCookiesState from '../utils/nextCookiesState'
import { useClientSideRendering } from 'hooks/useClientSideRendering'
import { useCurrentUrlInfo } from 'hooks/useCurrentUrlInfo'

import '../src/index.css'
import 'App.scss'
import TestModeNotification from '../src/components/TestModeNotification'
import ErrorBoundaryNextJS from '../components/errors/ErrorBoundaryNextJS'
import Snackbar from 'components/common/Snackbar'
import StoresDialogBox from 'components/home/ModalContent/StoresDialogBox'
import CouponModal from 'components/coupons/CouponModal'
import UrlActionHandler from 'HOCs/UrlActionHandler'
import { StoresProvider } from 'components/StoresProvider/StoresProvider'
import CouponsStore from 'mobx/Coupons/store'
import CouponsRepository from 'mobx/Coupons/repository'
import CouponFlowStore from 'mobx/CouponFlow/store'
import CouponFlowRepository from 'mobx/CouponFlow/repository'
import CouponFlowService from 'HOCs/CouponFlowService'
import { ACCEPTED_LANGUAGES } from '../utils/constants'
import CartStore from 'mobx/Cart/store'
import CartRepository from 'mobx/Cart/repository'
import CartService from 'HOCs/CartService'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode
}

const StickySpacer = styled.div`
	height: 70px;
	position: sticky;
`

const App = ({
	Component,
	pageProps,
	infra,
	user,
	error,
	forwardedUrl,
	traceId,
}: AppProps & {
	infra: {
		appParams: any
		appTheme: any
		eCommerceFooter: any
	}
	user: { userLocaleLanguage: any }
	error: string
	Component: NextPageWithLayout
	forwardedUrl: string
	traceId: string
}) => {
	const [, setKey] = useState(`${getRandomUUID()}_home`)
	const [showMobileBanner, setShowMobileBanner] = useState(false)
	const [store, setStore] = useState<Store>({
		data: null,
		metaData: null,
		searchResults: null,
	})
	const router = useRouter()
	const { query, dynamicRouteQuery } = useCurrentUrlInfo()
	const isClientSideRendering = useClientSideRendering()
	const ssrError = !infra?.appParams || !infra?.appTheme

	const [applicationCSRInitied, setApplicationCSRInitied] = useState(false)

	const pathname = usePathname()
	const hasPathnameChanged = useCompare(pathname)
	const stores = useMemo(() => {
		const cartStore = new CartStore(CartRepository)
		const couponsStore = new CouponsStore(CouponsRepository)
		const couponFlowStore = new CouponFlowStore(CouponFlowRepository)
		CartRepository.setCouponsStore(couponsStore)
		CouponFlowRepository.setCartStore(cartStore)
		CouponFlowRepository.setCouponsStore(couponsStore)
		CouponFlowRepository.setSetNotification(Infra.setNotification)
		CouponFlowRepository.setCloseNotification(Infra.closeNotification)
		CouponFlowRepository.setAreAllStoresClosed(Home.areAllStoresClosed)
		CouponFlowRepository.setGetTranslatedTextByKey(getTranslatedTextByKey)
		return { couponsStore, couponFlowStore, cartStore }
	}, [])

	useEffect(() => {
		CartRepository.setRouter(router)
		CouponFlowRepository.setRouter(router)
	}, [router])

	useEffect(() => {
		if (hasPathnameChanged) {
			Application.sendSetChannelEvent()
		}
	}, [hasPathnameChanged, pathname])

	useEffect(() => {
		// Used to remove the server-side generated styles of Material UI v4, important not to remove.
		const jssStyles = document.querySelector('#jss-server-side')
		if (jssStyles && jssStyles.parentNode) {
			jssStyles.parentNode.removeChild(jssStyles)
		}
		configAxios()
	}, [])

	useEffect(() => {
		// init all client side rendering code (access to JS objects window, document, location, etc...)
		const init = async () => {
			if (!error && !ssrError) {
				await Application.startNextCSRInit(
					dynamicRouteQuery?.lang as LanguageCode,
					Home.setLocale,
					User,
					Account,
					Infra,
					AddressManager,
					isMobileApp()
				)
				setApplicationCSRInitied(true)
			}
		}

		init()
	}, [dynamicRouteQuery?.lang, error, router?.asPath, router.query.lang, router.route, ssrError])

	useEffect(() => {
		if (applicationCSRInitied === true) {
			sendPageViewEvent(router?.asPath?.split('?')?.[0])
		}
	}, [applicationCSRInitied, router.route, ssrError, router?.asPath])

	useEffect(() => {
		// Handle entering/exiting testMode
		const testMode = Infra.getTestMode(query)
		Infra.setTestMode(testMode)
	}, [query])

	useEffect(() => {
		if (!error && isMobileApp()) {
			ReactNativeComms.init(stores, Infra, Account, Application, MobileApplication, infra.appParams?.mobileApp?.externalUrls)
		}

		// Initialize the user from Local Storage
		Account.getUser()
	}, [])

	useEffect(() => {
		setShowMobileBanner(infra?.appParams?.title && !isMobileApp())
	}, [infra?.appParams?.title, isMobileApp()])

	// TODO: temporary code to remove once all users of KFC Philippines app will using the new app (from tictuk)
	useEffect(() => {
		if (router.asPath.includes('/Redirection/NavigateToMobileApp')) {
			MobileApplication.openMobileForceAppUpdateDialog(Infra)
		}
	}, [])

	useEffect(() => {
		router.beforePopState(() => {
			if (stores.cartStore.itemModalOpen) {
				stores.cartStore.closeItemModal()
				// return false
			}

			if (stores.couponsStore.couponModal) {
				stores.couponsStore.setCouponModal(null)
				// return false
			}

			return true
		})
	}, [router, stores.cartStore, stores.couponsStore, stores.couponsStore.couponModal, stores.cartStore.itemModalOpen])

	useEffect(() => {
		// Hydrate the User mobx with delivery type
		const currentDeliveryTypeFromCookies = nextCookiesState.fromClient.get('currentDeliveryType', Cookies)
		User.setOrderType(currentDeliveryTypeFromCookies || CONSTANTS.DELIVERY_METHODS.DELIVERY)

		// Hydrate the Application mobx with isMobileApp
		Application.setIsMobileApp(isMobileApp())

		Infra.fetchTenantDetails(Application.backendChannel)
	}, [])

	// useEffect(() => {
	// 	if (process.env.NEXT_PUBLIC_HIDE_ERROR_MODAL === 'true') {
	// 		// eslint-disable-next-line prefer-destructuring
	// 		const sheet = window.document.styleSheets[0]
	// 		sheet.insertRule('nextjs-portal { display: none; }', sheet.cssRules.length)
	// 	}
	// })

	const smartBannerData = useMemo(() => {
		const { android, ios } = infra?.eCommerceFooter?.apps || {}
		const androidLink = android?.link || ''
		const iosLink = ios?.link || ''
		const bundleId = androidLink ? (extractQueryParamsFromUrl(androidLink)?.id as string) || '' : ''

		return { bundleId, iosLink }
	}, [infra?.eCommerceFooter?.apps])

	// This function protects the app from critical misconfiguration (in getInitialProps or getServerSideProps)
	if (error) {
		return <UnrecoverableServerError message={error} traceId={traceId} />
	}

	// This function protects the app from server side rendering error (at component level)
	if (ssrError) {
		return <UnrecoverableServerError message="A server side rendering error occured" traceId={traceId} />
	}

	// If Infra.appParams is not already set OR the data fetched on server side is different from the one we have on server persistant data
	// - infra.appParams is the data fetched on server side (see in getInitialProps)
	// - Infra.appParams is the server persistant data
	// - Without this condition, it throws an warning on local env (next-dev) "Cannot update a component (`wrappedComponent`) while rendering a different component (`App`)"
	if (!Infra.appParams.c || Infra.appParams.c !== infra?.appParams.c) {
		// Hydrate the Infra mobx with the required states, so they become available for all mobx stores and components
		Infra.setAppParams(infra?.appParams)
		Infra.setECommerceFooter(infra?.eCommerceFooter)
		Infra.setAppTheme(infra?.appTheme)

		// Hydrate the User mobx with the local translations
		User.userLocaleLanguage = user.userLocaleLanguage
	}

	// Hydrate the Application mobx with combined theme and pass isInitied to true
	// TODO FOR NEXT: pass the right value to the param 'locale' of buildCombinedTheme method
	Application.setCombinedTheme(buildCombinedTheme(infra?.appParams, infra?.appTheme, null))
	Application.setIsInited(true)
	;(async () => {
		// @ts-expect-error we're too lazy to define the main.css module
		await import('react-smartbanner/dist/main.css')
	})()

	// That's the recommended way to implement per page layout by NextJS
	const getLayout = Component.getLayout ?? ((page) => page)

	// Remove 'ref' from appParams because it throws an error on CustomHead (string ref prop not allowed on function component, see https://legacy.reactjs.org/docs/refs-and-the-dom.html#legacy-api-string-refs)
	const { ref, ...appParamsWithoutRef } = infra.appParams

	return (
		<div id="root">
			<CustomHead {...appParamsWithoutRef} lang={infra?.appParams.l} currentPageURL={`${forwardedUrl}${router.asPath}`} />
			{showMobileBanner && (
				<SmartBanner
					title={infra?.appParams?.title}
					onInstall={() => isiOS() && handleMobileApplicationDeepLink(smartBannerData.bundleId, '', smartBannerData.iosLink)}
				/>
			)}
			<Provider
				Infra={Infra}
				Home={Home}
				Application={Application}
				Account={Account}
				User={User}
				Payment={Payment}
				AddressManager={AddressManager}
				MobileApplication={MobileApplication}
				ItemAdditions={ItemAdditions}
			>
				{/* eslint-disable-next-line */}
				<ReloadContext.Provider value={{ setKey }}>
					<StoreContext.Provider
						value={{
							store,
							setStore,
						}}
					>
						<StoresProvider stores={stores}>
							<UrlActionHandler />
							<CouponFlowService />
							<CartService />
							<ThemeProvider theme={Application.combinedTheme}>
								{getLayout(
									<ErrorBoundaryNextJS>
										<Component {...pageProps} />
									</ErrorBoundaryNextJS>
								)}
								<MenuItemOverlay />
								<TestModeNotification />
								<Snackbar />
								<StoresDialogBox />
								<CouponModal />

								{isClientSideRendering && isMobileApp() && <StickySpacer />}
								{isClientSideRendering && isMobileApp() && <Footer history={router} location={router} />}
							</ThemeProvider>
						</StoresProvider>
					</StoreContext.Provider>
				</ReloadContext.Provider>
			</Provider>
		</div>
	)
}

/**
 * Initialize the app on the server side only.
 * The boolean serverSideRendering is necessary because getInitialProps is
 * called both on server side and after each page navigation on client side
 * See documentation:
 *    - https://nextjs.org/docs/api-reference/data-fetching/get-initial-props
 */

App.getInitialProps = async ({
	ctx,
}: {
	ctx: NextPageContext & {
		req: {
			extra: {
				appParams: any
				appFontsUrl: string
				nextJsError: string | null
				forwardedUrl: string
				eCommerceFooter: any
			}
		}
	}
}) => {
	if (!ctx.req || !ctx.res) {
		return {
			infra: Infra,
			user: User,
		}
	}

	// Execute on server only
	const lang = ctx.query?.lang as string

	try {
		if (lang && !ACCEPTED_LANGUAGES.includes(lang)) {
			ctx.res.statusCode = 404

			ctx.res.end()

			throw new Error(`${lang} language not supported ${ctx?.req?.url}`)
		}

		// App global data fetching
		const { appParams, appTheme, appFontsUrl, eCommerceFooter, userLocaleLanguage } = await appInit(ctx)

		const { forwardedProto } = extractUrlsFromNextRequest(ctx.req)
		const forwardedUrl = `${forwardedProto}://${ctx.req.headers.host}`

		// Set extra data to nextJS context request, to share data between _app and others pages
		ctx.req.extra = {
			appFontsUrl,
			appParams: {
				...appParams,
				environment: process.env.NEXT_PUBLIC_ENV,
				storefrontUrl: process.env.STOREFRONT_API_URL ? process.env.STOREFRONT_API_URL : appParams.storefrontUrl,
				wruec: process.env.ECOMMERCE_SERVER ? process.env.ECOMMERCE_SERVER : appParams.wruec,
			},
			nextJsError: null,
			forwardedUrl,
			eCommerceFooter,
		}

		return {
			infra: {
				appParams: {
					...appParams,
					environment: process.env.NEXT_PUBLIC_ENV,
					storefrontUrl: process.env.STOREFRONT_API_URL
				},
				appTheme,
				eCommerceFooter,
			},
			user: {
				userLocaleLanguage,
			},
			forwardedUrl,
			traceId: getRandomUUID(),
		}
	} catch (e: any) {
		;(ctx.req as any).extra = {
			nextJsError: e.toString(),
		}

		if (!ctx.res.statusCode || ctx.res.statusCode === 200) {
			ctx.res.statusCode = 500
		}

		return { error: safeStringify(e), traceId: getRandomUUID() }
	}
}

export default App
