import { filterToString } from 'components/coupons/CouponsFilters'
import type { Channel, Coupon, Exception, IndividualCoupon, InStoreCoupon } from 'shared-types/Coupon'
import { CouponSource, CouponStatus, OrderType } from 'shared-types/Coupon'
import type { FlagShortcuts, LocalizedCoupon } from 'types/Coupons'
import { couponFlags, IN_STORE_ORDER_TYPES } from 'types/Coupons'
import { buildChannelsErrorText } from 'utils/coupon/couponUtils'
import { getTranslatedTextByKey } from 'utils/language'
import type { Filter as CouponFilter } from './types'
import { CONSTANTS } from 'utils/constants'

export function isIndividual(coupon: Pick<Partial<Coupon>, 'source'>): coupon is IndividualCoupon {
	return !!coupon.source && coupon.source === CouponSource.INDIVIDUAL
}

export function isInStore(coupon: Pick<Coupon, 'orderTypes'>): coupon is InStoreCoupon {
	return IN_STORE_ORDER_TYPES.some((orderType) => coupon.orderTypes.includes(orderType))
}

export const addVirtualFields = <T extends Coupon | LocalizedCoupon>(coupon: T): T & FlagShortcuts => {
	Object.defineProperties(
		coupon,
		couponFlags.reduce(
			(acc, flag) => ({
				...acc,
				[flag]: {
					get() {
						console.log(`Getting ${flag} with value ${coupon.flags[flag]?.value} from ${coupon.code}`)
						return coupon.flags[flag]?.value
					},
					set(value: unknown) {
						console.log(`Setting ${flag} to ${value} in ${coupon.code}`)
						coupon.flags[flag] = { ...coupon.flags[flag], value: value as Exclude<FlagShortcuts[typeof flag], undefined> }
					},
				},
			}),
			{}
		)
	)

	return coupon
}

export const isCouponExpired = (coupon: Coupon): boolean =>
	coupon.status === CouponStatus.EXPIRED || (coupon.expiration?.getTime() ?? Infinity) < new Date().getTime()

export const isCouponAvailableAtDate = (coupon: Coupon, date = new Date()): boolean => {
	if (isCouponExpired(coupon) || coupon.status !== CouponStatus.AVAILABLE || Number.isNaN(date.getTime())) {
		return false
	}

	const {
		availability: { weekly, exceptions },
	} = coupon

	const availableExceptions = exceptions.filter(({ available }) => available)
	const unavailableExceptions = exceptions.filter(({ available }) => !available)

	const isWithinException = (exception: Exception): boolean =>
		exception.start.getTime() <= date.getTime() && exception.end.getTime() >= date.getTime()

	const startMinOfWeek = date.getDay() * 24 * 60
	const exactMinOfWeek = startMinOfWeek + date.getHours() * 60 + date.getMinutes()

	return (
		availableExceptions.some(isWithinException) ||
		(!unavailableExceptions.some(isWithinException) &&
			weekly.some(({ minuteOfWeek, durationMins }) => minuteOfWeek <= exactMinOfWeek && exactMinOfWeek < durationMins + minuteOfWeek))
	)
}

export const translateChannel = (channel: Channel): string =>
	getTranslatedTextByKey(`eCommerce.coupons.availability.channel.${channel.toLowerCase()}`, buildChannelsErrorText({ channels: [channel] }))

export const translateOrderType = (orderType: CouponFilter): string =>
	getTranslatedTextByKey(`eCommerce.coupons.filter.${orderType}`, filterToString[orderType])

export const toCouponOrderType = (orderType: number | string): OrderType => {
	const normalised = typeof orderType === 'string' ? orderType.toLowerCase() : orderType

	switch (normalised) {
		case CONSTANTS.DELIVERY_METHODS.DELIVERY:
		case CONSTANTS.ORDER_TYPE.DELIVERY:
			return OrderType.DELIVERY
		case CONSTANTS.ORDER_TYPE.PICKUP:
		case CONSTANTS.DELIVERY_METHODS.PICKUP:
			return OrderType.PICKUP
		case CONSTANTS.ORDER_TYPE.DRIVE_THRU:
			return OrderType.DRIVETHROUGH
		default:
			throw new Error(`Unknown order type: ${orderType}`)
	}
}
