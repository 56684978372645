import type React from 'react'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { useCurrentUrlInfo } from 'hooks/useCurrentUrlInfo'
import { useStores } from 'hooks/useStores'
import { inject, observer } from 'mobx-react'
import { readCouponDetailsFromUrl } from 'mobx/CouponFlow/utils'
import { CheckCouponOn } from 'mobx/CouponFlow'
import { CONSTANTS } from 'utils/constants'
import type _Infra from 'mobx/Infra'
import type _User from 'mobx/User'
import type _Home from 'mobx/Home'
import type _AddressManager from 'mobx/AddressManager'
import { StoreContext } from 'contexts/StoreContext'
import { useRouter } from 'next/router'
import { OrderType } from 'shared-types/Coupon'
import LocalizationError from 'mobx/AddressManager/errors'

const UrlActionHandler: React.FC<{
	Infra?: typeof _Infra
	User?: typeof _User
	AddressManager?: typeof _AddressManager
	Home?: typeof _Home
}> = ({ Infra, User, AddressManager, Home }) => {
	const { pathname, query: clientQuery, dynamicRouteQuery, hash, removeHash, asPath } = useCurrentUrlInfo()
	const router = useRouter()

	const handledHashes = useRef<Set<string>>(new Set())
	const forceLocalizationProcess = useRef<boolean>(false)

	const { couponFlowStore, cartStore } = useStores()
	const { setStore } = useContext(StoreContext)

	const handleExternalCouponFromUrlLegacy = useCallback(async () => {
		if (couponFlowStore.couponToApply || !hash || (hash && handledHashes.current.has(hash))) {
			return
		}

		const couponDetails = readCouponDetailsFromUrl({ query: clientQuery, hash })

		if (couponDetails) {
			handledHashes.current.add(hash)
			removeHash()
			await couponFlowStore.start({
				code: couponDetails.code,
				orderTypeToForce: couponDetails.orderTypeToForce,
				openModal: true,
			})
		}
	}, [couponFlowStore, clientQuery, hash, removeHash])

	const handleForceLocalization = useCallback(
		async (storeId: string) => {
			if (!storeId || !AddressManager || !User || !cartStore || !Home || !Infra) {
				return
			}
			await AddressManager.handleForceLocalization(storeId, User, cartStore, Home, Infra, router, setStore)
		},
		[AddressManager, User, cartStore, Home, Infra, router, setStore]
	)

	const showError = useCallback(
		(message: string) => {
			Infra?.setNotification?.({
				open: true,
				title: message,
				message: '',
				onClose: () => {
					Infra?.closeNotification()
				},
				okAction: () => {
					Infra?.closeNotification()
				},
			})
		},
		[Infra]
	)

	useEffect(() => {
		const handleRouteChange = async () => {
			if (hash?.startsWith(CONSTANTS.URL_QUERY_HASH_KEY.ITEM) || hash?.startsWith(CONSTANTS.URL_QUERY_HASH_KEY.DISCOUNT)) {
				await handleExternalCouponFromUrlLegacy()
				return
			}

			if (!clientQuery.has('forceLocalization') || clientQuery.get('forceLocalization') !== 'true' || forceLocalizationProcess.current) {
				return
			}

			try {
				forceLocalizationProcess.current = true

				const excludedKeys = new Set(['forceLocalization', 'storeId', 'couponCode'])
				const modifiedQuery: Record<string, string | string[]> = {}

				const storeId = clientQuery.get('storeId')
				const couponCode = clientQuery.get('couponCode')

				clientQuery.forEach((value, key) => {
					if (excludedKeys.has(key)) {
						return
					}

					if (!modifiedQuery[key]) {
						modifiedQuery[key] = value
						return
					}

					if (Array.isArray(modifiedQuery[key])) {
						;(modifiedQuery[key] as string[]).push(value)
					} else {
						modifiedQuery[key] = [modifiedQuery[key] as string, value]
					}
				})

				await router.replace(
					{
						pathname,
						query: {...dynamicRouteQuery, ...modifiedQuery},
					},
					undefined,
					{ shallow: true }
				)

				const userLocalized = User && AddressManager?.isUserLocalized()
				const currentStoreId = AddressManager?.dependencies.getStoreId()
				const currentOrderTypePickup = AddressManager?.localizedAddress.localizedOrderType === CONSTANTS.ORDER_TYPE.PICKUP

				// User already localized to the store, in pickup delivery
				if (userLocalized && currentStoreId === storeId && currentOrderTypePickup) {
					const { menuRes, storeMetaData, serverSession, menuUrl } = await AddressManager.onMenuClickWhenLocalized(cartStore, User)

					await User.setSession(serverSession)
					setStore((store) => ({ ...store, data: menuRes, metaData: storeMetaData }))
					await router.push(`${menuUrl}`)
				}

				// Start the localization flow
				else {
					await handleForceLocalization(storeId ?? '')
				}

				if (couponCode) {
					await couponFlowStore.start({
						code: couponCode,
						orderTypeToForce: OrderType.PICKUP,
						openModal: true,
						checkCouponOn: CheckCouponOn.STORE,
					})
				}
			} catch (error: unknown) {
				let message = ''
				if (error instanceof LocalizationError) {
					message = error.text()
				} else if (error instanceof Error) {
					message = error.message
				} else {
					message = 'Something went wrong, please try again.'
				}

				// remove query params from the URL in order to avoid infinite loop of error message
				await router.replace({
					pathname: asPath?.split('?')?.[0] ?? '/home',
				},
				undefined,
				{ shallow: true })

				showError(message)
			} finally {
				forceLocalizationProcess.current = false
			}
		}

		handleRouteChange()
	}, [
		pathname,
		clientQuery,
		hash,
		handleExternalCouponFromUrlLegacy,
		router,
		handleForceLocalization,
		couponFlowStore,
		showError,
		dynamicRouteQuery,
		asPath
	])

	return null // This component does not render anything
}

export default inject('Infra', 'User', 'AddressManager', 'Home')(observer(UrlActionHandler))
