// @ts-nocheck
import styled from 'styled-components/macro'
import { getDomainByEnv, sendRequest, normalizeFileNameStr } from 'utils/utils'

export const MapSection = styled.div`
	display: grid;
	grid-template-areas:
		'search map'
		'list map';
	grid-template-columns: 400px 1fr;
	gap: 20px;
	width: 100%;
	min-height: 500px;
	margin-bottom: 20px;

	@media (max-width: 576px) {
		position: relative;
		flex-direction: column;
		height: unset;

		grid-template-columns: 1fr;
		grid-template-areas:
			'search'
			'map'
			'list';
	}
`

export const MapContainer = styled.div`
	//width: 100%;
	//height: 100%;
	//flex: 1;

	grid-area: map;

	@media (max-width: 577px) {
		flex: unset;
		height: 300px;
		//margin: 15px 0;
	}
`

export const makeRequest = async (isGeneratedStatically, config, paramsJson) => {
	if (isGeneratedStatically) {
		return sendRequest(false, `${getDomainByEnv(paramsJson)}webFlowAddress?${config}`, 'get', null, null, true, 90000, null, false)
	}

	return sendRequest(false, `${getDomainByEnv(paramsJson)}webFlowAddress?${config}`, 'get', null, null, true, 90000, null, false)
}

export const getStorePath = (lang, storeName) => `/${lang}/store-locator/${normalizeFileNameStr(storeName)}`
