import React, { useState, useEffect, useContext, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import {
	formatPrice,
	getTranslatedTextByKey,
	isIPhoneX,
	isMobile,
	setItemsAPI,
	getDomainByEnv,
	getStore,
	initOrUpdateSession,
	getStoreName,
} from 'utils/utils'
import { finish } from 'utils/chatAppUtils'
import { StoreContext } from 'contexts/StoreContext'
import queryString from 'query-string'
import ShareWithFriendForm from '../menu/ShareWithFriendForm'
import styled, { css } from 'styled-components/macro'
import { sendEnhancedEcommerceEvent, sendCustomEvent, getRestaurantAnalyticsFields } from '../../utils/analytics/analytics'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import useSnackbar from 'hooks/useSnackbar'
import ButtonBase from 'components/common/ButtonBase'
import { useRouter } from 'next/router'
import { useStores } from 'hooks/useStores'
import type _User from 'mobx/User'
import type _Infra from 'mobx/Infra'
import type _Application from 'mobx/Application'
import type _Payment from 'mobx/Payment'
import type _Home from 'mobx/Home'

const EEE = CONSTANTS.ANALYTICS.ENHANCED_ECOMMERCE_EVENTS

const CheckOutButton = styled(ButtonBase)`
	width: 100%;
	display: flex;
	justify-content: space-between;
`

interface CheckOutButtonContainerCssProps {
	$mobileApp: boolean
	$isIPhoneX: boolean
	$fullWidth: boolean
}

const CheckOutButtonContainer = styled.div<CheckOutButtonContainerCssProps>`
	z-index: 2;
	position: fixed;
	width: ${({ $fullWidth }) => ($fullWidth ? 100 : 25)}%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	transition-property: bottom;
	transition-duration: 100ms;
	transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
	backdrop-filter: blur(8px);
	-webkit-backdrop-filter: blur(8px);
	padding: 0px 10px;
	bottom: 7px;

	@media (max-width: 576px) {
		padding: 20px;
		align-items: center;
		bottom: ${({ $mobileApp }) => ($mobileApp ? 65 : 0)}px;
		width: 100%;
	}

	${({ $isIPhoneX }) =>
		$isIPhoneX &&
		css`
			height: 115px;
			padding-bottom: 23px;
		`}
`

interface CheckoutButtonProps {
	User: typeof _User
	Infra: typeof _Infra
	Application: typeof _Application
	Payment: typeof _Payment
	Home: typeof _Home
	fullWidth: boolean
}

export const CheckoutButton = inject(
	'Infra',
	'User',
	'Application',
	'Payment',
	'Home'
)(
	observer((props: CheckoutButtonProps) => {
		const { Infra, Application, User, Home, Payment, fullWidth } = props
		const { cartStore } = useStores()
		const { store } = useContext(StoreContext)
		const rest = store.data
		const [buttonDisabled, setButtonDisabled] = useState(cartStore.numberOfItems === 0 || cartStore.loading)
		const checkoutBtnRef = useRef(null)
		const router = useRouter()

		useSnackbar(checkoutBtnRef, 'checkout-btn')

		const loadMenuAndInitSession = async (storeId: string, existingMenuPath: string) => {
			try {
				const orderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP
				const { wru = getDomainByEnv() } = Infra.appParams

				// if menuUrl exists in localstoage, we use it instead of starting a new session
				const newMenuPath =
					existingMenuPath ??
					(await initOrUpdateSession({
						storeId,
						refObject: { orderType },
						stopLoading: false,
						shouldRedirectIfError: false,
					}))

				if (newMenuPath) {
					const newMenuQueryParams = queryString.parse((newMenuPath ?? '').split('?')[1])
					storeId = process.env.NODE_ENV === 'production' ? storeId : `${storeId}`

					const storeMetaData = await getStore({
						wru,
						request: newMenuQueryParams.request,
						cust: newMenuQueryParams.cust,
						tictuk_listener: newMenuQueryParams.tictuk_listener,
					})

					return {
						metaData: storeMetaData,
						newMenuPath,
					}
				}
				return null
			} catch (error) {
				console.log(error)
				return null
			} finally {
				Infra.setLoading(false)
			}
		}

		const submitHandler = (formData: any) => {
			Infra.closeNotification()
			finish(null, cartStore.items, rest?.items, true, formData.name)
		}

		const checkout = async () => {
			const parsed = queryString.parse(window.location.search)

			if (parsed.shared) {
				// the OK button is provided by the <ShareWithFriendForm/> component since it can get the name from
				// the input and the outer DialogBox cannot so easily.
				Infra.setNotification({
					title: '',
					message: <ShareWithFriendForm closeForm={submitHandler} inviter={parsed.firstName} />,
				})
			} else if (
				[
					CONSTANTS.APP.TYPES.WEB.toString(),
					CONSTANTS.APP.TYPES.WEB_MOBILE.toString(),
					CONSTANTS.APP.TYPES.ANDROID_APP.toString(),
					CONSTANTS.APP.TYPES.IOS_APP.toString(),
				].includes(parsed?.app || User.session.appid.toString())
			) {
				const isCurrentPageStatic = window.location.href.includes('.html')

				// Used to get the previous menu page url to extract query parameters
				let existingMenuPath = null

				// call check_field...setItems which adds the cart to the server sync. AND checks if it has reached the min order
				if (!store.metaData || !window.location.pathname.includes('menu') || isCurrentPageStatic) {
					const metaDataAndMenuPath = await loadMenuAndInitSession(
						localStorage.getItem('storeId') || '',
						localStorage.getItem('menuUrl') || ''
					)
					store.metaData = metaDataAndMenuPath?.metaData ?? null
					existingMenuPath = metaDataAndMenuPath?.newMenuPath
				}

				const setItemsResponse = await setItemsAPI(cartStore.items, rest)
				if (!setItemsResponse) {
					return
				}

				sendCustomEvent({
					category: 'menu',
					action: 'button pressed',
					label: 'checkout',
				})

				const deliveryType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP

				sendEnhancedEcommerceEvent(
					EEE.sendCheckOutEvent,
					{ ...getRestaurantAnalyticsFields(rest), storeName: getStoreName(store, deliveryType, Home.locale.msg) },
					{
						step: CONSTANTS.CHECKOUT_STEPS.GOTO_CHECKOUT_PAGE,
						deliveryType,
						paymentType: Payment.paymentMethod,
						storeID: store?.data?.id || '',
						storeName: getStoreName(store, deliveryType, Home.locale.msg),
					},
					cartStore.getAnalyticsFields()
				)

				const locationSearch = (() => {
					if (!existingMenuPath && window.location.search) {
						return window.location.search
					}
					if (existingMenuPath) {
						const params = existingMenuPath.split('?')
						params.shift()
						return `?${params.join('')}`
					}
					throw new Error('No menu identified')
				})()

				router.push(`/checkout${locationSearch}`)
			} else {
				// show loader while calling /webview API
				finish(null, cartStore.items, rest?.items, true)
			}
		}

		useEffect(() => {
			const deliveryCharge = cartStore.charges.find((charge) => charge.type === ORDER_TYPES.DELIVERY)
			const deliveryFee = deliveryCharge?.amount || 0
			const _activateButton = cartStore.grandTotal - deliveryFee > 0 || cartStore.numberOfItems > 0
			setButtonDisabled(!_activateButton || cartStore.loading)
		}, [cartStore.charges, cartStore.grandTotal, cartStore.numberOfItems, cartStore.loading])

		const checkoutComponent = (
			<CheckOutButtonContainer
				$mobileApp={!!Application.isMobileApp}
				id="checkoutButton"
				$isIPhoneX={isIPhoneX()}
				ref={isMobile() ? checkoutBtnRef : null}
				$fullWidth={fullWidth}
			>
				<CheckOutButton data-testid="checkout-button" onClick={checkout} disabled={buttonDisabled} id="checkoutButton" $size="big" fullWidth>
					<span>{getTranslatedTextByKey('btns.moveOn')}</span>
					<span data-testid="total-price">{formatPrice(cartStore.grandTotal, rest?.currency || 'USD', rest?.countryCode || 'us')}</span>
				</CheckOutButton>
			</CheckOutButtonContainer>
		)

		return checkoutComponent
	})
)

export default CheckoutButton
