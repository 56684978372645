// @ts-nocheck
import React, { useState, useContext, useRef, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import CodeInput from 'react-code-input'
import { zodResolver } from '@hookform/resolvers/zod'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useTimer } from 'react-timer-hook'

import ErrorMessage from '../ErrorMessage'
import Skip from './Skip'
import TypographyPro from 'themes/TypographyPro'
import { SIGN_UP_STEPS } from 'constants/signUpProcess'
import { respondAbove } from 'styles/mixins'
import FriendlyCaptcha from './FriendlyCaptcha'

import { SubmitStyledButton, SubmitButtonContainer, Form, TitleContainer, StyledGridContainer } from './Steps.styles'
import { sendCustomEvent } from 'utils/analytics/analytics'
import { formatLocalDateTime, getStoreName, getTranslatedTextByKey, injectVarsToTemplateString } from 'utils/utils'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import { StoreContext } from 'contexts/StoreContext'
import { FeatureFlagEnum } from 'types/constants.types'
import { IdentifierType } from 'mobx/Infra/Infra.type'
import type _Account from 'mobx/Account'
import type _Infra from 'mobx/Infra'
import type _User from 'mobx/User'
import type _Home from 'mobx/Home'
import type _Application from 'mobx/Application'

const CODE_TIMER_MILLISECONDS = 60000 * 5
const CODE_LENGTH = 4

const CodeInputForm = styled(Form)`
	margin-top: 50px;
`

const StyledCodeInput = styled(CodeInput)(({ theme }) => ({
	'@media(max-height: 650px)': {
		marginTop: '10px',
	},
	'& input': {
		...theme.typography.h1,
		textAlign: 'center',
		background: 'rgba(0,0,0,0)',
		margin: '0 10px',
		padding: '0',
		border: 'none',
		boxShadow: 'none',
		borderBottom: '3px solid var(--fonts)',
		width: '60px',
		outline: 'none',
		'@media(max-width: 360px)': {
			width: '45px',
		},
	},
	'& input:last-child': {
		margin: '0 0 0 10px',
	},

	'& input[type=number]': {
		'-moz-appearance': 'textfield',
	},
	'& input[type=number]::-webkit-outer-spin-button': {
		'-webkit-appearance': 'none',
		margin: 0,
	},
	'& input[type=number]::-webkit-inner-spin-button': {
		'-webkit-appearance': 'none',
		margin: 0,
	},
}))

const Title = styled(TypographyPro)`
	line-height: 24px;
	text-transform: uppercase;
`

const DifferentNumberLink = styled(TypographyPro)`
	text-transform: none;
	color: var(--footerAndDarkBackgrounds);
	cursor: pointer;
`

const ResendCode = styled(TypographyPro)`
	color: var(--footerAndDarkBackgrounds);
	cursor: pointer;
`

const ResendCodeGrid = styled(Grid)`
	margin-top: 30px;
	align-items: center;

	${respondAbove.sm`
		align-items: unset;
	`}
`

const SubTitleContainerGrid = styled(Grid)`
	padding-block: 0.75rem;
	width: 337px;
	color: var(--fonts);
	text-transform: none;

	${respondAbove.sm`
		width: unset;
	`}
`

const ErrorContainerStyledGrid = styled(Grid)`
	width: 290px;
`

const FriendlyCaptchaContainer = styled.div`
	width: 100%;
	margin-top: 10px;
	max-width: none;
`

export const schema = z.object({
	code: z.string().length(CODE_LENGTH),
})

const VerifyCode = inject(
	'Account',
	'Infra',
	'Application',
	'User',
	'Home'
)(
	observer(
		({
			Account,
			Infra,
			User,
			Application,
			Home,
			onSkipClick,
			login,
		}: {
			Account: typeof _Account
			Infra: typeof _Infra
			User: typeof _User
			Home: typeof _Home
			Application: typeof _Application
			onSkipClick: any
			login: any
		}) => {
			const { sendMeCode, openSignUpPopUp, moveToSignUpStep, getIdentifier, verifyCode } = Account
			const [isCodeExpired, setIsCodeExpired] = useState(false)
			const { store } = useContext(StoreContext)

			const { seconds, minutes, restart } = useTimer({
				expiryTimestamp: Date.now() + CODE_TIMER_MILLISECONDS,
				onExpire: () => setIsCodeExpired(true),
			})

			const friendlyCaptchaEnabled = Infra.appParams.features.friendlyCaptcha?.enabled
			const [friendlyCaptchaShown, setFriendlyCaptchaShown] = useState<boolean>(false)
			const [friendlyCaptchaSolution, setFriendlyCaptchaSolution] = useState<undefined | string>(undefined)
			const friendlyCaptchaRef = useRef<{ start: () => void; reset: () => void }>()

			const _localDateTime = formatLocalDateTime(Infra.locale.msg)
			const identifier = getIdentifier()
			const differentIdentifierText =
				identifier.type === IdentifierType.Phone
					? getTranslatedTextByKey('eCommerce.signIn.differentNumber', 'Different phone number?')
					: getTranslatedTextByKey('eCommerce.signIn.differentEmail', 'Different email address?')

			const {
				setValue,
				setError,
				getValues,
				handleSubmit,
				formState: { errors, isDirty, isValid },
			} = useForm({
				resolver: zodResolver(schema),
				mode: 'onChange',
			})

			const onSubmit = async ({ code }) => {
				try {
					Infra.setLoading(true)

					// First step: verify the code (it also returns the user data)
					const userTokens = await verifyCode(code, Application.backendChannel)
					const additionalUserDataFilled = userTokens.user.firstName && userTokens.user.email && userTokens.user.phoneNumber
					const signInMandatoryEmail = Infra?.hasFeatureFlag(FeatureFlagEnum.SIGN_IN_MANDATORY_EMAIL)

					// Second step: check if login should occurs now, or later in ExtendedUserDetails
					// - if SIGN_IN_MANDATORY_EMAIL feature disabled OR user name/email already filled, we do the login
					// - if SIGN_IN_MANDATORY_EMAIL feature enabled AND user name/email empty, the login will occur in ExtendedUserDetails
					if (!signInMandatoryEmail || additionalUserDataFilled) {
						await login()
					}

					// If user name/email already filled, we close the sign up modal
					if (additionalUserDataFilled) {
						openSignUpPopUp(false)
					}

					// Else we move the user to ExtendedUserDetails page
					else {
						moveToSignUpStep(SIGN_UP_STEPS.EXTEND_USER_DETAILS)
					}
				} catch (error) {
					const errorResponseTextKey = error.response?.data.error.code
					const keyElements = errorResponseTextKey.split('.')
					setError('code', {
						type: 'manual',
						message: getTranslatedTextByKey(`eCommerce.signIn[${keyElements?.[0]}][${keyElements?.[1]}]`),
					})

					const orderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP
					sendCustomEvent({
						category: 'account',
						action: 'send code',
						loginMethod: Account.getIdentifier()?.type,
						label: 'failure',
						date: _localDateTime,
						storeID: store?.data?.id || '',
						storeName: getStoreName(store, orderType, Home.locale.msg) || '',
					})
				} finally {
					Infra.setLoading(false)
				}
			}

			const resendCode = async () => {
				try {
					const time1 = new Date()
					time1.setMinutes(time1.getMinutes() + 5)
					restart(time1)
					setIsCodeExpired(false)

					const orderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP
					sendCustomEvent({
						category: 'account',
						action: 'signup',
						label: 'resend code',
						loginMethod: Account.getIdentifier()?.type,
						date: _localDateTime,
						storeID: store?.data?.id || '',
						storeName: getStoreName(store, orderType, Home.locale.msg) || '',
					})
					Infra.setLoading(true)
					await sendMeCode(identifier.type, identifier.value, friendlyCaptchaSolution)
				} catch (error) {
					const errorResponseTextKey = error.response.data.error.code
					const keyElements = errorResponseTextKey.split('.')
					setError('code', {
						type: 'manual',
						message: getTranslatedTextByKey(`eCommerce.signIn[${keyElements?.[0]}][${keyElements?.[1]}]`),
					})

					const orderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP
					sendCustomEvent({
						category: 'account',
						action: 'signup',
						label: 'resend code error',
						loginMethod: Account.getIdentifier()?.type,
						date: _localDateTime,
						storeID: store?.data?.id || '',
						storeName: getStoreName(store, orderType, Home.locale.msg) || '',
					})
				} finally {
					Infra.setLoading(false)
				}
			}

			useEffect(() => {
				if (friendlyCaptchaSolution !== undefined) {
					resendCode()
				}
			}, [friendlyCaptchaSolution])

			const beforeResendCode = async () => {
				if (!friendlyCaptchaEnabled) {
					resendCode()
					return
				}

				// If captcha is already displayed, it means that user already try to resend the code
				// so we need to reset the captcha
				if (friendlyCaptchaShown === true) {
					friendlyCaptchaRef.current.reset()
				}
				// Else we mount it
				else {
					setFriendlyCaptchaShown(true)
				}
			}

			const renderIsCodeExpired = () => (
				<ResendCodeGrid container direction="column">
					<TypographyPro variant="BodyRegular">
						{getTranslatedTextByKey('eCommerce.signIn.codeWilLExpire')}
						&nbsp;
						<span>
							{minutes}:{seconds < 10 ? '0' : ''}
							{seconds}
						</span>
						&nbsp;
						{getTranslatedTextByKey('eCommerce.signIn.seconds')}
					</TypographyPro>

					<ResendCode variant="BodyRegularClickable" component="a" onClick={beforeResendCode}>
						{getTranslatedTextByKey('eCommerce.signIn.resendCode')}
					</ResendCode>
				</ResendCodeGrid>
			)

			const minimumCharactersMessage = injectVarsToTemplateString(
				`${getTranslatedTextByKey('webviewFlow.minimumCharacters', `Code length must be at least {#limit} characters long`)}`,
				{
					'#limit': CODE_LENGTH,
				}
			)
			const cannotBeEmptyMessage = getTranslatedTextByKey('webviewFlow.cannotBeEmpty', `Cannot be empty`)
			const mustBeValidNumber = getTranslatedTextByKey('webviewFlow.mustBeValidNumber', 'Must be a valid number')

			const getErrorMessage = (type) => {
				switch (type) {
					case 'string.empty':
						return cannotBeEmptyMessage
					case 'string.min':
					case 'too_small':
						return minimumCharactersMessage
					default:
						return mustBeValidNumber
				}
			}

			return (
				<StyledGridContainer>
					<TitleContainer container direction="column">
						<Title variant="h3">{getTranslatedTextByKey('eCommerce.signIn.weJustTextedYou')}</Title>
						<SubTitleContainerGrid>
							<TypographyPro variant="BodyRegular">{getTranslatedTextByKey('eCommerce.signIn.pleaseEnterCode')}</TypographyPro>
							<TypographyPro variant="BodyRegularHighlighted">{identifier.value}</TypographyPro>
						</SubTitleContainerGrid>
						<DifferentNumberLink
							variant="BodyRegularClickable"
							component="a"
							onClick={() => {
								moveToSignUpStep(SIGN_UP_STEPS.VERIFY_IDENTIFIER)
							}}
						>
							{differentIdentifierText}
						</DifferentNumberLink>
					</TitleContainer>
					<CodeInputForm onSubmit={handleSubmit(onSubmit)} data-testid="account-code-input">
						<StyledCodeInput
							autoComplete="off"
							type="number"
							inputStyleInvalid={{ borderColor: '#FF2E00' }}
							isValid={!errors.code && !isCodeExpired}
							fields={CODE_LENGTH}
							value={getValues('code')}
							onChange={(codeValue) => {
								setValue('code', codeValue, { shouldDirty: true, shouldValidate: true })
							}}
						/>
						<ErrorContainerStyledGrid data-testid="input-error-message">
							{isCodeExpired && <ErrorMessage message={getTranslatedTextByKey('eCommerce.signIn.verification.expired')} />}
							{errors.code?.message && !isCodeExpired && <ErrorMessage message={getErrorMessage(errors.code.type)} />}
						</ErrorContainerStyledGrid>
						{renderIsCodeExpired()}

						{friendlyCaptchaShown && (
							<FriendlyCaptchaContainer>
								<FriendlyCaptcha
									ref={friendlyCaptchaRef}
									onComplete={(solution) => {
										setFriendlyCaptchaSolution(solution)
									}}
									startMode="auto"
								/>
							</FriendlyCaptchaContainer>
						)}

						<SubmitButtonContainer>
							<SubmitStyledButton
								$size="big"
								disabled={!isDirty || !isValid || isCodeExpired}
								type="submit"
								data-testid="account-verify-code-button"
							>
								{getTranslatedTextByKey('eCommerce.signIn.submit')}
							</SubmitStyledButton>
						</SubmitButtonContainer>
					</CodeInputForm>
					{Account.signupDisplaySkipSection && (
						<Skip
							caption={getTranslatedTextByKey('eCommerce.signIn.skip')}
							onSkip={onSkipClick}
							or={getTranslatedTextByKey('eCommerce.signIn.or')}
						/>
					)}
				</StyledGridContainer>
			)
		}
	)
)

export default VerifyCode
