// @ts-nocheck
import React, { useState, useRef, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import { isMobile, getTranslatedTextByKey } from 'utils/utils'
import { capitalize } from 'lodash-es'
import styled, { css } from 'styled-components/macro'
import TypographyPro from 'themes/TypographyPro'
import { sendCustomEvent } from '../../utils/analytics/analytics'
import TextField from 'components/common/TextField'
import SpecialDiscountWarnings from './SpecialDiscountWarnings'
import SpecialDiscount from './SpecialDiscount'
import { useStores } from 'hooks/useStores'
import { CheckCouponOn } from 'mobx/CouponFlow'
import type _Infra from 'mobx/Infra'
import type _Application from 'mobx/Application'
import CouponError, { ErrorCode } from 'mobx/Coupons/errors'

const StyledButton = styled(Button)`
	color: var(--primary);
	text-transform: capitalize;
	padding: 0;

	&.MuiButtonBase-root.Mui-disabled {
		color: var(--primary);
	}

	&:hover {
		color: var(--secondary);
	}
`

const StyledCouponContainer = styled.div`
	${({ $isSummaryPage, $hasItems }) =>
		$isSummaryPage &&
		$hasItems &&
		css`
			background-color: white;
			position: relative;
			bottom: 20px;
			@media (max-width: 576px) {
				bottom: 50px;
			}
		`}
	${({ $disableCouponsField }) =>
		$disableCouponsField &&
		css`
			filter: grayscale(100);
			opacity: 0.4;
		`}

	border: 2px dashed var(--highlights);
	padding: 3px;
	border-radius: 4px;
`

interface Props {
	className?: string
	isSummary?: boolean
	isMenu?: boolean
}

const CouponCode = inject(
	'Application',
	'Infra'
)(
	observer((props: Props & { Infra: typeof _Infra; Application: typeof _Application }) => {
		const { Application, Infra, className } = props
		const [code, setCode] = useState('')
		const couponCodeRef = useRef()
		const focusRef = useRef()
		const isSummary = props.isSummary || (props.isMenu && !isMobile())
		const { couponFlowStore, cartStore } = useStores()

		const disabledCouponCode = useMemo(
			() => cartStore.loading || cartStore.serverDisableCouponsField || cartStore.serverDisableSpecialDiscounts,
			[cartStore.serverDisableCouponsField, cartStore.serverDisableSpecialDiscounts, cartStore.loading]
		)

		const checkCoupon = async (couponCode) => {
			const trimmedCode = (couponCode ?? '').trim()

			sendCustomEvent({
				category: 'coupon',
				action: 'check',
				label: trimmedCode,
			})

			couponFlowStore
				.start({
					code: trimmedCode,
					orderTypeToForce: null,
					checkCouponOn: CheckCouponOn.STORE,
					openModal: false,
				})
				.then((result) => {
					if (!result) {
						Infra.setNotification({
							message: new CouponError('Coupon not found', ErrorCode.COUPON_NOT_FOUND).text(),
							okAction: Infra.closeNotification,
						})
					}
				})

			setCode('')
		}

		const changeHandler = (e) => {
			setCode(e.target.value)
		}

		const onKeyDown = (event) => {
			// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
			if (event.key === 'Enter' && code !== '') {
				checkCoupon(code)
			}
		}

		return (
			<>
				<SpecialDiscountWarnings />
				<StyledCouponContainer
					ref={couponCodeRef}
					$isSummaryPage={isSummary}
					$hasItems={props.numOfItems > 0}
					$disableCouponsField={disabledCouponCode}
					className={className}
				>
					<TextField
						value={code}
						placeholder={capitalize(getTranslatedTextByKey('typeAcouponCode', 'type a coupon code'))}
						id={`${Application.page}-page-coupon-input`}
						inputProps={{
							'aria-label': getTranslatedTextByKey('typeAcouponCode', 'type a coupon code'),
							maxLength: 30,
							variant: 'BodySmall',
							'data-testid': 'menu-coupon-input',
						}}
						onChange={changeHandler}
						onKeyPress={onKeyDown}
						inputRef={focusRef}
						disabled={disabledCouponCode}
						endAdornment={
							<InputAdornment position="end">
								<StyledButton
									onClick={() => checkCoupon(code)}
									disabled={!code}
									type="submit"
									aria-label="search"
									data-testid="apply-coupon-button"
								>
									<TypographyPro variant="CTAText">{getTranslatedTextByKey('applyCoupon')}</TypographyPro>
								</StyledButton>
							</InputAdornment>
						}
					/>
				</StyledCouponContainer>
				<SpecialDiscount />
			</>
		)
	})
)

export default CouponCode as React.FC<Props>
