import { checkRequest, getStore, initOrUpdateSession } from 'utils/utils'
import loadMenu from 'utils/api/loadMenu/loadMenu'

const setMenuUrl = (url: string) => localStorage.setItem('menuUrl', url)

const getMenuUrl = () => localStorage.getItem('menuUrl')

const deleteMenuUrl = () => localStorage.removeItem('menuUrl')

const getStoreId = () => localStorage.getItem('storeId')

const handleCheckRequest = ({
	wru,
	request,
	cust,
	storeId,
	tictuk_listener,
}: {
	wru: string
	request: string
	cust: string
	storeId: string
	tictuk_listener: string
}) =>
	checkRequest({
		wru,
		request,
		cust,
		j: storeId,
		tictuk_listener,
	})

const handleLoadMenu = ({
	chainId,
	storeId,
	orderTypeFromQS,
	appId,
	stopLoading,
}: {
	chainId: string
	storeId: string
	orderTypeFromQS: string
	appId: number
	stopLoading: boolean
}) =>
	loadMenu({
		chainId,
		storeId,
		orderTypeFromQS,
		appId,
		stopLoading,
	})

const handleGetStore = ({ wru, request, cust, tictuk_listener }: { wru: string; request: string; cust: string; tictuk_listener: string }) =>
	getStore({
		wru,
		request,
		cust,
		tictuk_listener,
	})

const handleInitNewSession = ({
	ref,
	wru,
	storeId,
	pc,
	stopLoading = false,
}: {
	ref: string
	wru: string
	storeId: string
	pc: string
	stopLoading: boolean
}) => {
	let refObject = {}
	try {
		const refDecodedString = decodeURIComponent(ref)
		refObject = JSON.parse(refDecodedString)
	} catch (err) {}
	return initOrUpdateSession({ refObject, wru, storeId, chainId: pc, stopLoading })
}

export default {
	setMenuUrl,
	getMenuUrl,
	getStoreId,
	deleteMenuUrl,
	handleCheckRequest,
	handleGetStore,
	handleLoadMenu,
	handleInitNewSession,
}
