import Infra from 'mobx/Infra'
import User from 'mobx/User'
import queryString from 'query-string'

import { ORDER_TYPES } from 'utils/constants'
import type { LanguageCode } from 'utils/utils'
import { initOrUpdateSession, sendRequest, getDomainByEnv, replaceTokenInText, getTranslatedTextByKey, checkRequest } from 'utils/utils'

import type { OrderEntity } from 'types/OrderEntity'

export const initSessionFromReOrder = async (orderData: OrderEntity) => {
	const newMenuPath = await initOrUpdateSession({
		refObject: { orderType: orderData.orderType || Infra?.appParams.ot },
		storeId: orderData.restid,
		stopLoading: false,
	})

	// When a redirect occurs from init new session above, we get en empty newMenuPath
	// Therefore, the execution of the function can be stopped here
	if (!newMenuPath) {
		console.warn('No new menu path received')
		return
	}

	const newMenuQueryParams = queryString.parse((newMenuPath ?? '').split('?')[1])
	const { wru } = queryString.parse(newMenuPath)

	const serverSession = await checkRequest({
		wru,
		request: newMenuQueryParams.request,
		cust: newMenuQueryParams.cust,
		storeId: newMenuQueryParams.j,
		tictuk_listener: newMenuQueryParams.tictuk_listener,
	})

	await User.setSession(serverSession)
	localStorage.setItem('sessionId', User.session._id)
	const [, query] = (newMenuPath || '').split('?')
	localStorage.setItem('menuUrl', `?${query.split('#')[0]}`)

	localStorage.setItem('storeId', orderData.restid)

	return newMenuPath
}

export const isOldOrdersDeliveryStoreOpen = async (storeId: string, address: any, chainId: string, preferredLanguage: LanguageCode | null) => {
	// call getBranchByAddress and pass the old order's address. If the store's ID is in the response then the store is open
	const res: any = await sendRequest(
		true,
		`${getDomainByEnv()}webFlowAddress`,
		'post',
		{
			chainId,
			type: 'getBranchByAddress',
			addr: { formatted: address.formatted, lat: address.lat, lng: address.lng },
			addressVal: address.formatted,
			cust: 'openRest',
			lang: preferredLanguage,
		},
		{ 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
	)

	console.log(res)

	if (!res.error && res.msg?.length > 0) {
		for (const store of res.msg) {
			if (store.id === storeId && store.deliveryAllowed) {
				// store is open and allows delivery
				console.info(`store with id: ${storeId} is open and allows delivery`)
				return true
			}
		}
	}

	return false
}

export const isOldOrdersPickupStoreOpen = async (storeId: string, chainId: string) => {
	// call getBranchesList and check for the store's ID. If it's present, the store is open.
	const res: any = await sendRequest(
		false,
		`${getDomainByEnv()}webFlowAddress`,
		'post',
		{
			chainId,
			lang: 'en',
			type: 'getBranchesList',
			cust: 'openRest',
		},
		{
			'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
		}
	)

	// console.log(res)

	if (!res.error && res.msg?.pickupStores?.length > 0) {
		for (const store of res.msg.pickupStores) {
			if (store.id === storeId && store.pickUpAllwed) {
				// store is open and allows delivery
				console.info(`store with id: ${storeId} is open and allows pickup`)
				return true
			}
		}
	}

	return false
}

export const canReOrder = async (orderData: OrderEntity): Promise<boolean> => {
	let reorderAllowed = false
	let invalidReason = ''

	if (orderData.orderType === ORDER_TYPES.DELIVERY) {
		reorderAllowed = await isOldOrdersDeliveryStoreOpen(orderData.restid, orderData.address, Infra?.appParams.c, User?.preferredLanguage)
		if (!reorderAllowed) {
			invalidReason = replaceTokenInText(getTranslatedTextByKey('webviewFlow.reorder.storeClosed'), { storeName: orderData.restName })
		}
	} else if (orderData.orderType === ORDER_TYPES.PEAKUP) {
		reorderAllowed = await isOldOrdersPickupStoreOpen(orderData.restid, Infra?.appParams.c)
		if (!reorderAllowed) {
			invalidReason = replaceTokenInText(getTranslatedTextByKey('webviewFlow.reorder.storeClosed'), { storeName: orderData.restName })
		}
	} else {
		console.error(`re-order - unknown order type: ${orderData.orderType}`)
		reorderAllowed = false
		invalidReason = `Unknown order type: ${orderData.orderType}`
	}

	if (!reorderAllowed) {
		Infra?.setNotification({
			title: getTranslatedTextByKey('webviewFlow.reorder.unavailable'),
			message: invalidReason,
			cancelText: getTranslatedTextByKey('webviewFlow.reorder.goBack', 'Go Back'),
			cancelAction: () => {
				Infra?.closeNotification()
			},
		})
	}

	return reorderAllowed
}
