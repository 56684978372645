import type React from 'react'
import { useEffect, useContext } from 'react'
import { useStores } from 'hooks/useStores'
import { observer, inject } from 'mobx-react'
import type _User from 'mobx/User'
import type _AddressManager from 'mobx/AddressManager'
import { StoreContext } from 'contexts/StoreContext'
import { useRouter } from 'next/router'
import { getTenantInfo } from 'utils/utils'

const CartService: React.FC<{ User?: typeof _User; AddressManager?: typeof _AddressManager }> = ({ User, AddressManager }) => {
	const { pathname } = useRouter()
	const { cartStore, couponFlowStore } = useStores()
	const { store } = useContext(StoreContext)
	const { data: menu } = store
	const userLocalized = AddressManager?.isUserLocalized()

	useEffect(() => {
		const tenantId = getTenantInfo()
		if (!userLocalized || !menu?.id || menu.id === tenantId || cartStore.loading || couponFlowStore.couponToApply) {
			return
		}

		if (User?.session && User?.session?._id && !cartStore.ready) {
			cartStore.fetchCart()
		}
	}, [cartStore, User, User?.session, menu?.id, cartStore.ready, pathname, userLocalized, cartStore.loading, couponFlowStore.couponToApply])

	return null
}

export default inject('User', 'AddressManager')(observer(CartService))
