import React from 'react'
import CouponAvailability, { AvailabilityState } from './Availability'
import IconComponent from 'themes/IconComponent'
import { translateOrderType } from 'mobx/Coupons/utils'
import styled from 'styled-components'
import TypographyPro from 'themes/TypographyPro'
import { getTranslatedTextByKey } from 'utils/utils'
import { OrderType } from 'shared-types/Coupon'

interface Props {
	orderTypes: OrderType[]
	currentOrderType: OrderType
}

const Icon = styled(IconComponent)`
	color: var(--fonts);
`

const orderTypeToIcon: Record<OrderType, JSX.Element> = {
	[OrderType.DELIVERY]: <Icon asset="/icons/order_type_delivery.svg" />,
	[OrderType.PICKUP]: <Icon asset="/icons/order_type_pickup.svg" />,
	[OrderType.IN_STORE]: <Icon asset="/icons/order_type_in_store.svg" />,
	[OrderType.DRIVETHROUGH]: <Icon asset="/icons/order_type_drivethrough" />,
}

const ChipContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
`

const Chip = styled.div`
	padding: 0.25rem 0.5rem;
	background: var(--optionalBackground);
	border-radius: 4px;
	width: fit-content;
	display: flex;
	align-items: center;
	gap: 4px;
`

const Text = styled(TypographyPro).attrs({ variant: 'BodySmall' })`
	color: var(--fontsColor);
`

const OrderTypeAvailability: React.FC<Props> = ({ orderTypes, currentOrderType }) => {
	const available = orderTypes.includes(currentOrderType)

	return (
		<CouponAvailability
			icon={<IconComponent asset="/icons/delivery_methods.svg" />}
			title={getTranslatedTextByKey(
				`eCommerce.coupons.availability.${available ? 'availableFor' : 'notAvailableFor'}.${currentOrderType}`,
				`${available ? 'Available' : 'Unavailable'} for ${translateOrderType(currentOrderType)}`
			)}
			state={available ? AvailabilityState.Available : AvailabilityState.Unavailable}
		>
			<ChipContainer>
				{orderTypes.map((orderType) => (
					<Chip key={orderType}>
						{orderTypeToIcon[orderType]}
						<Text>{translateOrderType(orderType)}</Text>
					</Chip>
				))}
			</ChipContainer>
		</CouponAvailability>
	)
}

export default OrderTypeAvailability
