// @ts-nocheck

import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Keyboard, Autoplay, A11y } from 'swiper'
import { isNodeJsEnv } from 'utils/utils'
import { respondAbove, breakpoints } from 'styles/mixins'
import styled from 'styled-components'

// only import when not statically rendering the page, to prevent rendering errors from occurring
if (!isNodeJsEnv) {
	// swiper bundle styles
	import(/* webpackMode: "eager" */ 'swiper/swiper-bundle.min.css')

	// swiper core styles
	import(/* webpackMode: "eager" */ 'swiper/swiper.min.css')

	// modules styles
	import(/* webpackMode: "eager" */ 'swiper/components/navigation/navigation.min.css')
	import(/* webpackMode: "eager" */ 'swiper/components/pagination/pagination.min.css')
}

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Keyboard, Autoplay, A11y])

const StyledSwiper = styled(Swiper)`
	width: 100%;

	.swiper-pagination-bullet {
		box-sizing: border-box;
		opacity: 1;
		background: var(--carouselArrBg);
		backdrop-filter: blur(4px);
		border: 1px solid var(--favoritesUnselected);
		height: 10px;
		width: 10px;
	}

	.swiper-pagination-bullet-active {
		background: var(--highlights);
	}

	.swiper-button-next,
	.swiper-button-prev,
	.swiper-button-prev,
	.swiper-container-rtl .swiper-button-next {
		color: black;
		background: rgba(255, 255, 255, 0.4);
		backdrop-filter: blur(4px);
		right: 10px;
		border-radius: 50%;
		height: 35px;
		width: 35px;
		display: flex;
		align-items: center;
		justify-content: center;

		${respondAbove.sm`
            height: 39px;
		    right: 20px;
            width: 39px;
        `}

		&::after {
			// This should not be themed, it controls the icon size
			font-size: 12px !important;
			font-weight: bold;

			${respondAbove.sm`
                font-size:18px !important;
    	    `}
		}
	}

	.swiper-button-prev,
	.swiper-container-rtl .swiper-button-next {
		right: unset;
		left: 10px;

		${respondAbove.sm`
            left: 20px;
        `}
	}
`

const BannerCarousel = ({
	showPagination = false,
	showArrows = false,
	autoplay = false,
	autoplayDelay = 300,
	itemGap: spaceBetween = 0,
	slidesPerView = 1,
	slidesPerGroup = 1,
	rows: slidesPerColumn = 1,
	loop = false,
	slideBreakpoints = {
		[breakpoints.sm]: {
			slidesPerView: 3,
		},
		[breakpoints.lg]: {
			slidesPerView: 4,
		},
	},
	isRtl = false,
	onSlideChange = (data) => {},
	children,
	...other
}) => {
	const options = {
		navigation: React.Children.count(children) > 1 && showArrows,
		pagination: React.Children.count(children) > 1 && showPagination ? { clickable: true } : false,
		autoplay:
			autoplay && React.Children.count(children) > 1 ? { delay: autoplayDelay, disableOnInteraction: false, pauseOnMouseEnter: true } : false,
		spaceBetween,
		slidesPerGroup,
		slidesPerView,
		slidesPerColumn,
		loop,
		dir: isRtl ? 'rtl' : 'ltr',
		breakpoints: slideBreakpoints,
		onSlideChange,
		...other,
	}

	return (
		<StyledSwiper mousewheel keyboard touchStartPreventDefault={false} watchOverflow {...options} onSlideChange={onSlideChange}>
			<div className="swiper-container">
				{React.Children.map(children, (child, index) => (
					<SwiperSlide key={child.key || index} virtualIndex={index} data-testid={`banner-slide-${index}`}>
						{child}
					</SwiperSlide>
				))}
			</div>
		</StyledSwiper>
	)
}

export default BannerCarousel
