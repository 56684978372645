import { GOOGLE_API_KEY } from '../constants'
import { Loader } from '@googlemaps/js-api-loader'
import { isEmpty } from 'lodash'

let googleMapsAPIObj: any = null

const loadGoogleMapsApi = async (apiKey: string) => {
	const loader = new Loader({
		apiKey: apiKey || GOOGLE_API_KEY,
	})

	return Promise.all([loader.importLibrary('maps'), loader.importLibrary('places')])
}

const getGoogleMapAPIObj = async (apiKey: string) => {
	if (isEmpty(window.google?.maps)) {
		await loadGoogleMapsApi(apiKey)
		return window.google.maps
	}
}

export const geoCode = async (options: google.maps.GeocoderRequest, apiKey: string): Promise<google.maps.GeocoderResult> => {
	googleMapsAPIObj = window.google?.maps
	if (isEmpty(googleMapsAPIObj)) {
		googleMapsAPIObj = await getGoogleMapAPIObj(apiKey)
	}

	const geocoder = new googleMapsAPIObj.Geocoder()
	return new Promise((resolve) => {
		geocoder.geocode(options, (responses: any[]) => {
			resolve(responses?.[0])
		})
	})
}
